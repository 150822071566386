import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import { withContext } from '../../context/withContext'
import { GetLastPipeline } from '../../helpers/functions'
import { Classes, Popover2 } from '@blueprintjs/popover2'
import { Button } from '@blueprintjs/core'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'

function PipelineSelector(props) {
	const [pipelines, setPipelines] = React.useState([])
	const [pipeline, setPipeline] = React.useState(null)

	React.useEffect(() => {
		// console.log(props.context.settings.pipeline)
		setPipelines(props.context.settings.pipeline)
		setPipeline(props.pipeline)
	}, [props.pipeline])
	if (pipeline !== null) {
		return (
			<ContentPipe>
				<Popover2
					canEscapeKeyClose
					usePortal={false}
					placement="auto"
					minimal
					interactionKind="click"
					popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
					placement="bottom"
					content={
						<ul>
							{pipelines.map((e, key) => (
								<Li active={e.uid === props.pipeline} onClick={() => props.onChange(e.uid)} key={key}>
									{e.name}
								</Li>
							))}
						</ul>
					}
					renderTarget={({ isOpen, ref, ...targetProps }) => (
						<>
							<Pipeline {...targetProps} elementRef={ref} className="d-inline">
								<strong>{pipelines.find((e) => e.uid === pipeline).name} </strong>
							</Pipeline>
							<FeatherIcon icon="external-link" size="15" stroke="#777" />
						</>
					)}
				/>
			</ContentPipe>
		)
	} else {
		return <></>
	}
}

const Li = styled.li`
	${(props) =>
		props.active &&
		`background-color:#467fcf !important;
        color:#fff;
    `}
`

const Pipeline = styled.button`
	background-color: transparent;
	border: none;
	outline: none;
	/* width: 200px; */
	text-align: left;
	transition: all 0.3s;
	:focus {
		outline: none;
		border: none;
	}
	strong {
		color: #495057;
		cursor: pointer;
	}
`

const ContentPipe = styled.span`
	position: relative;
	display: inline-block;
	.bp3-popover2.bp3-popover2-content-sizing .bp3-popover2-content {
		width: 250px;
	}
	.bp3-overlay-content {
		top: 25px !important;
	}
	.bp3-popover2-content {
		padding: 0 !important;
	}
	ul {
		list-style-type: none;
		padding: 5px 0;
		margin: 0;
		li {
			cursor: pointer;
			transition: all 0.3s;
			padding: 5px 15px;
			:hover {
				background-color: #f3f3f3;
			}
		}
	}
`

export default withContext(PipelineSelector)
