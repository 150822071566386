import * as React from 'react'
import { EditorState, SelectionState, Modifier } from 'draft-js'
import { getSelectedBlocksMap } from 'draftjs-utils'
import { flow } from 'lodash'
import styled from 'styled-components'

const styles = ['BOLD', 'ITALIC', 'UNDERLINE']

const removeInlineStyles = (editorState) => {
	const contentState = editorState.getCurrentContent()
	const contentWithoutStyles = styles.reduce(
		(newContentState, style) => Modifier.removeInlineStyle(newContentState, editorState.getSelection(), style),
		contentState
	)

	const newEditorState = EditorState.push(editorState, contentWithoutStyles, 'change-inline-style')

	return newEditorState
}

const removeEntities = (editorState) => {
	const contentState = editorState.getCurrentContent()
	const contentWithoutEntities = Modifier.applyEntity(contentState, editorState.getSelection(), null)

	const newEditorState = EditorState.push(editorState, contentWithoutEntities, 'apply-entity')

	return newEditorState
}

const removeBlockTypes = (editorState) => {
	const contentState = editorState.getCurrentContent()
	const blocksMap = getSelectedBlocksMap(editorState)
	const contentWithoutBlocks = blocksMap.reduce((newContentState, block) => {
		const blockType = block.getType()
		if (blockType !== 'unstyled') {
			const selectionState = SelectionState.createEmpty(block.getKey())
			const updatedSelection = selectionState.merge({
				focusOffset: 0,
				anchorOffset: block.getText().length,
			})

			return Modifier.setBlockType(newContentState, updatedSelection, 'unstyled')
		}

		return newContentState
	}, contentState)

	const newEditorState = EditorState.push(editorState, contentWithoutBlocks, 'change-block-type')

	return newEditorState
}

export function RichTextCleaner(props) {
	const handleClearClick = () => {
		const { editorState, onChange } = props

		const stateWithoutEditing = flow([removeInlineStyles, removeEntities, removeBlockTypes])(editorState)

		onChange(stateWithoutEditing)
	}

	return (
		<div className="rdw-remove-wrapper" aria-label="rdw-remove-control" onClick={handleClearClick}>
			<IconTx className="rdw-option-wrapper" title="Remove">
				<span>T</span>
				<small>
					<strong>x</strong>
				</small>
			</IconTx>
		</div>
	)
}

const IconTx = styled.div`
	position: relative;
	span {
		font-size: 17px;
	}
	small {
		left: 15px;
		bottom: 1px;
		font-size: 8px;
		font-weight: bold;
		position: absolute;
	}
`
