import { AETracker } from '../Components/AETracker'
import moment from 'moment'
import { Mixpanel } from '../Components/Mixpanel'

export function validateEmail(email) {
	var re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}
export function fixContent() {
	// Se encarga de eliminar el padding a los contenedores
	let doc = document.getElementsByClassName('content')
	if (doc.length > 0) {
		return (doc[0].style.padding = 0)
	}
	return false
}

export function LoginAndTokenData(response_, event) {
	let response = Object.assign({}, response_)
	// Función utilizada en ValidateToken, Logins, Authentications (Obligatorio)
	let company = response.data.company.company // Datos de la compañía completo
	let agents = response.data.agents // Todos los agentes de la inmobiliaria
	let settings = response.data.company // La configuración de la compañía y la compañía como tal
	let user = response.data.user // Datos del usuario
	let subscription = response.data.company?.company?.subscription // Datos de la suscripción de su compañía
	let returnData = {}
	settings.contact_type = response.data.ctypes // Tipos de contactos
	settings.org_companies = response.data.org_companies // Compañías relacionadas
	settings.pipeline = response.data.pipeline // Embudos de la compañía
	settings.site_configs = response.data.site_configs // Configuraciones del sitio web
	settings.company = company // asigna el company en el settings

	if (settings.site_configs[0]) {
		// Configuración de su página web
		settings.siteconfig_id = settings.site_configs[0].id
	}
	returnData = {
		user: response.data.user,
		brand: response.data.brand,
		company: company.uid,
		settings: settings,
		agents: agents,
		token: response.data.token,
		isLogged: true,
		loadingApp: false,
		field_types: response.data.field_types,
	}
	if (typeof response.data.pending_pay !== 'undefined') {
		returnData['pending_pay'] = response.data.pending_pay
	}
	if (user.company && user) {
		// Setup Mixpanel
		Mixpanel.identify(user.id)
		Mixpanel.people.set({
			$name: `${user.first_name} ${user.last_name}`,
			$first_name: user.first_name,
			$last_name: user.last_name,
			$email: user.email,
			$phone: user.phone,
			$avatar: user.avatar,
			$company: user.company,
			$user_type: user.user_type,
			$company_size: agents && agents.length,
			$company_active: settings.company.is_active,
			$is_admin: user.permits.is_admin,
			$subscription_status: subscription ? subscription.status : 'not_activated',
			$country_read: company.country_read,
		})
		window.userGuiding.identify(user.id, {
			email: user.email,
			name: `${user.first_name} ${user.last_name}`,
			first_name: user.first_name,
			last_name: user.last_name,
			subscription_status: subscription ? subscription.status : 'not_activated',
		})
		if (event) AETracker(event)
	}

	return returnData
}
