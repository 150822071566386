import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Help from '../elements/Help'
import { withContext } from '../../context/withContext'
import { FastSearchProperties } from '../../API/properties'
import styled from 'styled-components'
import { RenderPrice } from '../../helpers/returnValue'

function SearchableProperties(props) {
	const [properties, setProperties] = useState([])
	const [loading, setLoading] = useState(false)
	const [value, setValue] = useState(props.isMulti ? [] : undefined)
	const [debounce, setDebounce] = useState(0)

	useEffect(() => {
		return () => {
			if (props.defaultValue) setValue(props.defaultValue)
		}
	}, [])

	useEffect(() => {
		if (props.defaultValue) setValue(props.defaultValue)
	}, [props.defaultValue])

	const handleChange = (element) => {
		if (props.returnFullValue) {
			props.onChange(element)
		} else {
			if (props.isMulti) {
				let arr = []
				for (let x in element) {
					arr.push(element[x].uid)
				}
				setValue(element)
				if (props.onChange) props.onChange(arr)
			} else {
				setValue(element)
				if (props.onChange && element !== null) {
					props.onChange(element.uid)
				} else {
					props.onChange(null)
				}
			}
		}
	}

	const loadOptions = (inputValue, callback) => {
		let token = props.context.token
		let customQuery = inputValue
		clearTimeout(debounce)
		if (props.customQuery) {
			customQuery = `${inputValue}&${props.customQuery}`
		}
		setDebounce(
			setTimeout(() => {
				return FastSearchProperties(customQuery, token).then((response) => {
					setProperties(response.data.results)
					console.log(response.data.results)
					return callback(response.data.results)
				})
			}, 500)
		)
	}

	return (
		<Div showPadding={!props.isMulti && props.defaultValue} className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label className="control-label" htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			<AsyncSelect
				cacheOptions
				formatOptionLabel={formatPropertyUnitLabel}
				getOptionLabel={(val) => val.name}
				getOptionValue={(val) => val.uid}
				//
				isMulti={props.isMulti}
				isClearable={props.isClearable}
				value={value}
				loadedOptions={props.defaultValue}
				noOptionsMessage={() => 'No encontramos propiedades.'}
				defaultOptions
				loadOptions={loadOptions}
				isLoading={loading}
				placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
				isDisabled={loading}
				onChange={handleChange}
				classNamePrefix="react-select"
				closeMenuOnSelect={props.closeMenuOnSelect}
			/>
		</Div>
	)
}
export default withContext(SearchableProperties)

export const formatPropertyUnitLabel = ({
	private_name,
	name,
	project_values,
	cid,
	room,
	bathroom,
	parkinglot,
	floor_level,
	property_area,
	property_area_measurer,
	uid,
	is_children,
	featured_image,
}) =>
	is_children ? ( // En caso de que sea un proyecto
		<MediaProperty className="media">
			<img
				className="mr-3 property_featured_image"
				src={
					featured_image ||
					'https://d2p0bx8wfdkjkb.cloudfront.net/static/properties/IC5SCLAY3F/UHJ2RAW7C2/u9ikyoNR5P/SINFOTO.jpg'
				}
			/>
			<div className="media-body">
				<span className="d-block">{`${private_name ? private_name : name}`}</span>
				<small className="low-opacity">
					Desde <RenderPrice currency={project_values?.currency_min_sale} value={project_values?.min_sale} />{' '}
					hasta <RenderPrice currency={project_values?.currency_max_sale} value={project_values?.max_sale} />
				</small>
				<small className="d-block low-opacity">#{cid}</small>
			</div>
		</MediaProperty>
	) : (
		<MediaProperty className="media">
			<img
				className="property_featured_image mr-3"
				src={
					featured_image ||
					'https://d2p0bx8wfdkjkb.cloudfront.net/static/properties/IC5SCLAY3F/UHJ2RAW7C2/u9ikyoNR5P/SINFOTO.jpg'
				}
			/>
			<div className="media-body">
				<span className="d-block">{`${private_name ? private_name : name}`}</span>
				<small className="low-opacity">
					<ul className="react-select-ul">
						{room && (
							<li>
								<span className="card__property-ammenities-bed"></span> {room}
							</li>
						)}
						{bathroom && (
							<li>
								<i className="fas fa-bath"></i> {bathroom}
							</li>
						)}
						{parkinglot && (
							<li>
								<i className="fas fa-car"></i> {parkinglot}
							</li>
						)}
						{floor_level && (
							<li>
								<img
									src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/MR.HOME/2020/7/9X9vKu5MZF-stairs.svg"
									height="15px"
								/>{' '}
								{floor_level}
							</li>
						)}
						{property_area && (
							<li>
								<span className="card__property-ammenities-size"></span> {property_area}{' '}
								{property_area_measurer}
							</li>
						)}
					</ul>
				</small>
				<small className="d-block low-opacity">#{cid}</small>
			</div>
		</MediaProperty>
	)
const MediaProperty = styled.div`
	img.property_featured_image {
		width: 60px;
		height: 55px;
		border-radius: 5px;
		object-fit: cover;
	}
`
const Div = styled.div`
	${(props) =>
		props.showPadding
			? `.react-select__value-container {
        padding: 10px;
    }`
			: ''}
`
