import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import { FilterProposals } from '../../API/proposals'
import { withContext } from '../../context/withContext'

function SearchableProposal(props) {
    const [proposals, setProposals] = useState([])
    const [loading] = useState(false)
    const [value, setValue] = useState(props.isMulti ? [] : undefined)
    const [data, setData] = useState(props.isMulti ? [] : '')
    const token = props.context.token
    const [debounce, setDebounce] = useState(0)

    useEffect(() => {
        if (props.defaultValue) setValue(props.defaultValue)
        FilterProposals(token, '').then((response) => {
            setProposals(response.data.results)
        })
        return () => {}
    }, [])

    const handleChange = (element) => {
        if (props.isMulti) {
            let arr = []
            for (let x in element) {
                arr.push(element[x].value)
            }
            setData(arr)
            setValue(element)
            if (props.onChange) props.onChange(arr)
        } else {
            setData(element.value)
            setValue(element)
            if (props.onChange) props.onChange(element)
        }
    }

    const loadOptions = async (inputValue, callback) => {
        let qs = '&full_name=' + inputValue
        let arr = []
        clearTimeout(debounce)
        setDebounce(
            setTimeout(() => {
                return FilterProposals(token, qs).then((response) => {
                    setProposals(response.data.results)
                    for (let x = 0; x < response.data.results.length; x++) {
                        let data = response.data.results[x]
                        arr.push({ label: data.contact.full_name, value: data.properties })
                    }
                    return callback(arr)
                })
            }, 500)
        )
    }

    return (
        <div className={props.sm ? 'form-group-sm' : 'form-group'}>
            {props.label ? (
                <label htmlFor={props.id}>
                    {props.label}: {props.required ? <span className="danger">*</span> : null}
                </label>
            ) : null}
            <AsyncSelect
                isMulti={props.isMulti}
                isClearable={props.isClearable}
                value={value}
                loadedOptions={props.defaultValue}
                defaultOptions
                loadOptions={loadOptions}
                isLoading={loading}
                placeholder={props.placeholder}
                isClearable={props.isClearable}
                isDisabled={loading}
                onChange={handleChange}
                classNamePrefix="react-select"
            />
        </div>
    )
}
export default withContext(SearchableProposal)
