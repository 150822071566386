import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { withContext } from '../context/withContext'

function MobileFooterBar(props) {
	let settings = props.context.settings
	const Links = [
		{ text: 'Escritorio', link: '/', icon: 'clipboard', new: false, view: true },
		{ text: 'Negocios', link: '/deals/pipeline', icon: 'trending-up', new: false, view: settings.deals_app },
		{ text: 'Contactos', link: '/contacts', icon: 'user', new: false, view: true },
		{ text: 'Actividades', link: '/activities', icon: 'calendar', new: false, view: settings.activities_app },
		{ text: 'Propiedades', link: '/properties?status=1', icon: 'home', new: false, view: true },
	]
	const PushTop = () => {
		window.scrollTo(0, 0)
	}
	return (
		<Container>
			{Links.map((e, key) => {
				return (
					<Link to={e.link} key={key}>
						<Item onClick={PushTop} view={e.view}>
							<center>
								<FeatherIcon size={20} icon={e.icon} />
								{e.text}
							</center>
						</Item>
					</Link>
				)
			})}
		</Container>
	)
}
const Container = styled.div`
	@media only screen and (max-width: 600px) {
		display: flex;
	}
	display: none;
	background-color: #fff;
	position: fixed;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #eee;
	justify-content: space-around;
	z-index: 10;
	a {
		text-decoration: none;
		color: #616161;
		:active {
			text-decoration: none !important;
		}
	}
`
const Item = styled.div`
	${(props) =>
		!props.view &&
		css`
			display: none;
		`}
	text-align: center;
	transition: all 0.3s;
	padding-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 7px;
	font-size: 12px;
	text-decoration: none !important;
	a,
	a:hover,
	a:active {
		text-decoration: none !important;
	}
	svg {
		display: block;
	}
	:active {
		background-color: #317ae2;
		color: #fff;
	}
`
export default withContext(MobileFooterBar)
