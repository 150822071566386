export const theme = {
	primary_1: '#42906F',
	primary_2: '#E8C253',
	primary_3: '#F5E9B7',
	// Secondary
	secondary_1: '#3475E0',
	// Complementary
	comp_orange: '#E08734',
	comp_green: '#469665',
	comp_red: '#E55757',
	comp_yellow: '#FFEEC4',
	// Opacity Colors

	// Gray Colors
	gray: '#eeeeee',
	gray_1: '#E1E5EC',
	gray_2: '#BEBCBC',
	gray_3: '#1C434C',
	gray_4: '#7A848D',
	border_gray: '#F9FBFC',
	border_gray_2: '#F5F6F8',
}
