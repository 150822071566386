import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default function PageNotFound(props) {
    return (
        <Container>
            <Link to="/"><h4>Volver al Inicio</h4></Link>
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
`