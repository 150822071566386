import React from 'react'
import styled, { css } from 'styled-components'

const TableCheckbox = (props) => {
	return (
		<Checkbox
			type="checkbox"
			checked={props.checked}
			data-value={props.dataValue}
			data-id={props.dataId}
			id={props.id}
			onClick={props.onClick}
			onChange={props.onChange}
			disabled={props.disabled}
		/>
	)
}

export default TableCheckbox

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	position: relative;
	top: -1px;
	appearance: none;
	outline: none;
	border: 1px solid #e2e1e1;
	border-radius: 4px;
	background: #fff;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:checked {
		background: #42906f;
		border: 1px solid #42906f;
		color: #fff;
		&:after {
			content: '';
			position: absolute;
			top: 3px;
			left: 7px;
			width: 5px;
			height: 10px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);

			border-color: #fff;
		}
	}

	${(props) =>
		props.disabled &&
		css`
			background: #e2e1e1;
			border: 1px solid #e2e1e1;
			color: #fff;
		`}
`
