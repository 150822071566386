import React from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from './utils/theme'
// Local imports
import 'react-calendar/dist/Calendar.css'
import 'react-image-lightbox/style.css'
import 'react-circular-progressbar/dist/styles.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Principal from './Principal'
import UserProvider from './context/UserProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'

function App() {
	React.useEffect(() => {
		if (!!window.Intercom) {
			window.Intercom('boot', {
				app_id: 'p1d5po0z',
				hide_default_launcher: true,
			})
		}
	}, [])

	return (
		<GoogleOAuthProvider clientId="297175591795-0e8dtefd4lvjbomu6k00qkaec5oh8am5.apps.googleusercontent.com">
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<UserProvider>
						<Principal />
					</UserProvider>
				</BrowserRouter>
			</ThemeProvider>
		</GoogleOAuthProvider>
	)
}

export default App
