import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import chroma from 'chroma-js'
// local import
import Help from '../elements/Help'
import { withContext } from '../../context/withContext'
import { FilterTags } from '../../API/core'

function SearchableTags(props) {
	const [options, setOptions] = useState([])
	const { app, defaultValue } = props // app === Que tipo de aplicación es
	const token = props.context.token

	useEffect(() => {
		RetrieveData()
	}, [app, defaultValue])

	const RetrieveData = async () => {
		let qs = `app=${app ? app : ''}`
		try {
			let res = await FilterTags(token, qs)
			setOptions(res.data)
		} catch (error) {
			console.log(error)
		}
	}
	return props.columns === 2 ? (
		<Container className={props.sm ? 'form-group-sm' : 'form-group'}>
			<div className="row">
				<div className="col-xs-12 col-md-12 col-lg-4 text-right-lg">
					{props.label ? (
						<label className="control-label" htmlFor={props.id}>
							{props.label}: {props.required ? <span className="danger">*</span> : null}
						</label>
					) : null}
					{props.help ? <Help id={props.id} help={props.help} /> : null}
				</div>
				<div className="col-xs-12 col-md-12 col-lg-6">
					<Select
						{...props}
						required={props.required}
						isMulti={props.isMulti}
						options={options}
						classNamePrefix="react-select"
						placeholder={props.placeholder ? props.placeholder : 'Etiqueta'}
						isDisabled={props.isDisabled}
						isClearable={props.isClearable}
						value={options.filter((value) => value.id === props.defaultValue)}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option}
						onChange={props.onChange}
						styles={colourStyles}
					/>
				</div>
			</div>
		</Container>
	) : (
		<Container className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			{props.isMulti ? (
				<Select
					{...props}
					required={props.required}
					isMulti={props.isMulti}
					options={options}
					classNamePrefix="react-select"
					placeholder={props.placeholder ? props.placeholder : 'Etiqueta'}
					isDisabled={props.isDisabled}
					isClearable={props.isClearable}
					defaultValue={props.defaultValue}
					value={!props.value ? undefined : options.filter(({ id }) => props.value.includes(id))}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					onChange={props.onChange}
					styles={colourStyles}
				/>
			) : (
				<Select
					{...props}
					required={props.required}
					isMulti={props.isMulti}
					options={options}
					classNamePrefix="react-select"
					placeholder={props.placeholder ? props.placeholder : 'Etiqueta'}
					isDisabled={props.isDisabled}
					isClearable={props.isClearable}
					value={options.filter(
						(value) => value.uid === props.defaultValue || value.id === props.defaultValue
					)}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option}
					onChange={props.onChange}
					styles={colourStyles}
				/>
			)}
		</Container>
	)
}
const dot = (color = '#ccc') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: 'block',
		marginRight: 8,
		height: 10,
		width: 10,
	},
})
const colourStyles = {
	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = chroma(data.color)
		return {
			...styles,
			backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
			color: isDisabled
				? '#000'
				: isSelected
				? chroma.contrast(color, 'white') > 2
					? 'white'
					: 'black'
				: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
			},
		}
	},
	input: (styles) => ({ ...styles, ...dot() }),
	placeholder: (styles) => ({ ...styles, ...dot() }),
	singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
}

const Container = styled.div`
	position: relative;
	input.hidden-el {
		position: absolute;
		top: 0;
	}
`

export default withContext(SearchableTags)
