import React from 'react'

export default function ToggleSwitch(props) {
	return (
		<label className={props.className && props.className + ' custom-switch'}>
			<input
				type="checkbox"
				required={props.required}
				id={props.id}
				name={props.id}
				value={props.value}
				checked={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				className="custom-switch-input"
			/>
			<span className="custom-switch-indicator"></span>
			<span className="custom-switch-description">{props.label}</span>
		</label>
	)
}
