import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Help from '../elements/Help'
import { withContext } from '../../context/withContext'
import { RoundRobinsAPI } from '../../AdminAPI/intranet'
// import { RoundRobinsAPI } from '../../API/intranet'

function SearchableRoundRobin(props) {
	const [loading] = useState(false)
	const [value, setValue] = useState(props.isMulti ? [] : undefined)
	const [debounce, setDebounce] = useState(0)

	useEffect(() => {
		return () => {
			if (props.defaultValue) setValue(props.defaultValue)
		}
	}, [])

	useEffect(() => {
		console.log('run', props.defaultValue)
		if (props.defaultValue) setValue(props.defaultValue)
	}, [props.defaultValue])

	const handleChange = (element) => {
		if (props.isMulti) {
			let arr = []
			for (let x in element) {
				arr.push(element[x].value)
			}
			setValue(element)
			if (props.onChange) {
				if (props.ReactSelect) {
					props.onChange(element)
				} else {
					props.onChange(arr)
				}
			}
		} else {
			setValue(element)
			if (props.onChange && element !== null) {
				if (props.ReactSelect) {
					props.onChange(element)
				} else {
					props.onChange(element.value)
				}
			} else {
				props.onChange(null)
			}
		}
	}

	const loadOptions = (inputValue, callback) => {
		let qs = `?company=${props.company}&name=${inputValue}`
		let arr = []
		clearTimeout(debounce)
		setDebounce(
			setTimeout(() => {
				return RoundRobinsAPI(qs).then((response) => {
					for (let x = 0; x < response.data.results.length; x++) {
						let data = response.data.results[x]
						arr.push({
							label: data.name,
							value: data.uid,
						})
					}
					return callback(arr)
				})
			}, 500)
		)
	}

	return (
		<div className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			<AsyncSelect
				isMulti={props.isMulti}
				isClearable={props.isClearable}
				value={value}
				loadedOptions={props.defaultValue}
				defaultOptions
				loadOptions={loadOptions}
				isLoading={props.isLoading}
				placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
				isDisabled={loading}
				onChange={handleChange}
				classNamePrefix="react-select"
			/>
		</div>
	)
}
export default withContext(SearchableRoundRobin)
