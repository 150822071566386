import { Mixpanel } from './Mixpanel'

// Herramienta de rastreo de eventos
export const AETracker = (event_name, metadata) => {
	if (process.env.NODE_ENV === 'development') {
		console.log('TRACKING DISABLED / DEV MODE')
		return false
	} else {
		try {
			if (metadata) {
				Mixpanel.track(event_name, metadata)
				window.Intercom('trackEvent', event_name, metadata)
			} else {
				Mixpanel.track(event_name)
				window.Intercom('trackEvent', event_name)
			}
			return true
		} catch (error) {
			console.log(error)
			return false
		}
	}
}
