import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import Help from '../elements/Help'

export default function SelectSearchable(props) {
	// console.log(props.defaultValue === 'NaN')
	const options = props.options
	return props.columns === 2 ? (
		<Container className={props.sm ? 'form-group-sm' : 'form-group'}>
			<div className="row">
				<div className="col-xs-12 col-md-12 col-lg-4 text-right-lg">
					{props.label ? (
						<label className="control-label" htmlFor={props.id}>
							{props.label}: {props.required ? <span className="danger">*</span> : null}
						</label>
					) : null}
					{props.help ? <Help id={props.id} help={props.help} /> : null}
				</div>
				<div className="col-xs-12 col-md-12 col-lg-6">
					<Select
						required={props.required}
						isMulti={false}
						isLoading={props.isLoading}
						options={options}
						classNamePrefix="react-select"
						defaultValue={props.initValue}
						placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
						isDisabled={props.isDisabled}
						isClearable={props.isClearable}
						value={options.filter((value) => value[props.index] === props.defaultValue)}
						getOptionLabel={props.getOptionLabel}
						getOptionValue={props.getOptionValue}
						isOptionDisabled={props.isOptionDisabled}
						formatOptionLabel={props.formatOptionLabel}
						onChange={props.onChange}
					/>
				</div>
			</div>
		</Container>
	) : (
		<Container className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			<Select
				required={props.required}
				isMulti={false}
				options={options}
				classNamePrefix="react-select"
				defaultValue={props.initValue}
				placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
				isDisabled={props.isDisabled}
				isClearable={props.isClearable}
				value={options.filter((value) => value[props.index] === props.defaultValue)}
				getOptionLabel={props.getOptionLabel}
				getOptionValue={props.getOptionValue}
				isOptionDisabled={props.isOptionDisabled}
				formatOptionLabel={props.formatOptionLabel}
				onChange={props.onChange}
			/>
		</Container>
	)
}
const Container = styled.div`
	position: relative;
	input.hidden-el {
		position: absolute;
		top: 0;
	}
`
// https://github.com/JedWatson/react-select/issues/2669
