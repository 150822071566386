import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import { DateInput } from '@blueprintjs/datetime'
import Help from '../elements/Help'
import moment from 'moment'

export default function InputDate(props) {
	return (
		<div className="form-group" {...props.containerProps}>
			{props.label && (
				<label className="w-100">
					<span>
						{props.label}: {props.required && <span className="danger">*</span>}{' '}
						{props.help ? <Help id={props.id} help={props.help} /> : null}
					</span>
				</label>
			)}
			<ContentField className="w-100">
				<DateInput
					{...props}
					highlightCurrentDay
					formatDate={(date) => moment(date).format('DD/MM/YYYY')}
					parseDate={(str) => new Date(str)}
				/>
			</ContentField>
		</div>
	)
}

const ContentField = styled.div`
	position: relative;
	span,
	.bp3-input-group {
		width: 100%;
	}
	input {
		box-shadow: none;
		height: 38px;
		display: block;
		width: 100% !important;
		padding: 0.375rem 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.6;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0, 40, 100, 0.12) !important;
		border-radius: 3px;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
`
