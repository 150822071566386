import React, { useState } from 'react'
import { AccountDropdown } from 'tabler-react'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import AsyncSelect from 'react-select/async'
// Local Import
import { ChangeUserCompany, FilterCompanies } from '../AdminAPI/intranet'
import { withContext } from '../context/withContext'
import { ViewBrandAPI } from '../API/core'
// import MasterSearch from '../Components/MasterSearch'

const cookies = new Cookies()

function Topbar(props) {
	let navigate = useNavigate()
	const user = props.context.user
	const permits = props.context.user.permits
	const [datacompanies, setCompanies] = useState([])
	const [companydefault, setCompanyDefault] = useState({
		value: permits.company,
		label: user.company,
	})

	const [debounce, setDebounce] = useState(0)

	// Functions
	const handleLogout = async () => {
		try {
			cookies.remove('token')
			cookies.remove('company')
			localStorage.removeItem('pipeline_filters')
			let response = await ViewBrandAPI(window.location.host)
			let state = {
				token: null,
				company: null,
				collapseSidebar: cookies.get('toggle'),
				isLogged: false,
				isActive: false,
				brand: response.data,
				user: {},
				settings: {},
				filters: {},
			}
			navigate('/')
			props.updateContextVal(state)
		} catch (error) {
			console.log(error)
		}
	}

	const loadData = (inputValue, callback) => {
		clearTimeout(debounce)
		setDebounce(
			setTimeout(() => {
				return FilterCompanies(`name=${inputValue}&sort=name`).then((response) => {
					const companies = response.data.results
					let newArray = []
					for (let x in companies) {
						newArray.push({
							value: companies[x].name.replace(/\W/g, ''),
							label: (
								<div>
									<CompanyName>{companies[x].name}</CompanyName>
									<CompanyInfo className="company-info-select">
										<small>Registro: {moment(companies[x].timestamp).format('DD/MM/YYYY')}</small>
										<Badge is_active={companies[x].is_active}>
											{companies[x].is_active ? 'Activo' : 'Inactivo'}
										</Badge>
									</CompanyInfo>
								</div>
							),
							data: companies[x],
						})
					}
					return callback(newArray)
				})
			}, 500)
		)
	}

	const handleChangeCompany = async (company_new) => {
		if (!company_new) return

		// obtener datos del context
		const uid = props.context.user.uid

		let dataContext = { ...props.context }
		dataContext.company = company_new.uid
		dataContext.user.company = company_new.value

		try {
			await ChangeUserCompany({ company: company_new.data.uid }, uid)
			props.updateContextVal(dataContext)
		} catch (error) {
			alert(error, 'error al editar el usuario')
		}
	}
	return (
		<div className="header-margin no-print">
			<div className="header">
				<Container className="d-flex">
					<AdminAnnouncement className="d-flex order-lg-1">Administrador</AdminAnnouncement>
					<div className="d-flex order-lg-2 ml-auto" style={{ alignItems: 'flex-start' }}>
						<AsyncSelect
							classNamePrefix="react-select"
							loadOptions={loadData}
							defaultOptions={datacompanies}
							defaultValue={companydefault || 'No selecionado'}
							className="react-select-container"
							placeholder="Cambiar de compañía"
							onChange={handleChangeCompany}
						/>
						<AccountDropdown
							avatarURL={user.avatar}
							name={user.first_name + ' ' + user.last_name}
							description={user.position}
							options={[
								{
									to: '/settings/account',
									icon: 'settings',
									value: 'Ajustes',
								},
								{
									onClick: handleLogout,
									icon: 'log-out',
									value: 'Salir',
								},
							]}
						/>
					</div>
				</Container>
			</div>
		</div>
	)
}

const Container = styled.div`
	padding-bottom: 10px;
	.react-select__control {
		min-width: 200px;
	}
`

const AdminAnnouncement = styled.div`
	margin-left: 2rem;
	background: #bb6043;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	font-weight: 600;
	display: flex;
	align-items: center;
	@media only screen and (max-width: 600px) {
		margin-left: 10px;
		font-size: 12px;
		padding: 5px 5px;
	}
`
const CompanyInfo = styled.span`
	color: #888;
	font-weight: 300;
`
const Badge = styled.span`
	right: 0;
	float: right;
	margin-top: 3px;
	padding: 1px 5px;
	border-radius: 3px;
	font-size: 10px;
	background-color: ${(props) => (props.is_active ? '#ccffe4' : '#fef3c7')} !important;
	color: ${(props) => (props.is_active ? '#00aa4f' : '#92400e')} !important;
`
const CompanyName = styled.p`
	font-size: 14px;
	color: #333;
	font-weight: 400;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	margin-top: 0;
`

export default withContext(Topbar)
