import { API, IntranetAPI, APISocket } from '../utils/config'

export function FilterAll(token, qs) {
    return API(`/search/?q=${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function FilterUsers(token, qs) {
    return API(`/users/${qs ? `?${qs}` : ''}`, { headers: { Authorization: 'Token ' + token } })
}
export function TerminalSectorsAPI(token, qs) {
    return API(`/terminal/sectors/${qs ? `?${qs}` : '?country=149'}`, { headers: { Authorization: 'Token ' + token } })
}
// ESSENTIALS
export function GetCompanies(token) {
    return API('/companies/', { headers: { Authorization: 'Token ' + token } })
}
export function GetCountries(params) {
    return API(`/countries/?${params}`)
}
export function GetCountriesWithProperties(token, params) {
    return API(`dashboard/countries/?${params}`, { headers: { Authorization: 'Token ' + token } })
}
export function ListProvinces(qs) {
    return API(`/provinces/${qs ? qs : ''}`)
}
export function ListCities(qs) {
    return API(`/cities/${qs ? qs : ''}`)
}
export function ListSectors(qs) {
    return API(`/sectors/${qs ? qs : ''}`)
}
export function ListSectorsIntranet(qs) {
    return IntranetAPI(`/sectors/${qs ? qs : ''}`)
}
export function LocationsAPI(qs, country, data, extra_params) {
    // qs = Data que va haciendo input el usuario
    // country = Country del cual quiere filtrar
    // data = en caso de que sea un array el devuelve los nombres de eso
    // extra_params = En caso de que quiera aplicar filtros especiales
    if (data) {
        return API.post(`/location/?q=${qs}&country=${country}`, data)
    } else {
        return API(`/location/?q=${qs}&country=${country}${extra_params ? `${extra_params}` : ''}`)
    }
}
export function GetAmenities(id) {
    return API('/sectors/amenities/' + id + '/')
}
export function GetCategories() {
    return API('/categories/')
}
export function GetBanks(country) {
    return API(`/banks/?country=${country}`)
}
export function ValidateToken(token) {
    return API.post('/intranet/validateToken/', { token: token })
}
export function MoveUserAnotherCompany(data) {
    return API.post('/intranet/users/move_user/', data)
}
export function ImportCSV(token, data) {
    return API.post('/dashboard/import/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ChangeMyPassword(token, data) {
    return API.patch('/changePassword/', data, { headers: { Authorization: 'Token ' + token } })
}

// DIVISIONS
export function GetDivisions(token) {
    return API('/dashboard/divisions/', { headers: { Authorization: 'Token ' + token } })
}
export function NewDivision(token, data) {
    return API.post('/dashboard/divisions/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ReasignDivision(token, pk, data) {
    return API.patch(`/dashboard/divisions/reasign/${pk}/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function EditDivision(token, id, data) {
    return API.patch('/dashboard/divisions/edit/' + id + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function DeleteDivision(token, uid) {
    return API.delete('/dashboard/divisions/delete/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
}
// DASHBOARD
export function GetAgents(token, qs) {
    return API('/dashboard/agents/' + qs, { headers: { Authorization: 'Token ' + token } })
}
export function DeleteAgent(token, uid, data) {
    return API.post('/dashboard/agents/delete/' + uid + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function HandleUserActivation(token, uid, data) {
    return API.patch('/dashboard/agents/activation/' + uid + '/', data, {
        headers: { Authorization: 'Token ' + token },
    })
}
export function ReorderAgents(data, token) {
    return API.patch('/dashboard/agents/reorder/', data, { headers: { Authorization: 'Token ' + token } })
}
export function UpdateUser(token, uid, data) {
    if (data) {
        return API.patch('/dashboard/agents/edit/' + uid + '/', data, { headers: { Authorization: 'Token ' + token } })
    } else {
        return API('/dashboard/agents/edit/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
    }
}
export function SubmitUser(token, data) {
    return API.post(`dashboard/agents/new/`, data, { headers: { Authorization: `Token ${token}` } })
}
export function ViewUser(token, uid) {
    return API(`/users/${uid}/`, { headers: { Authorization: `Token ${token}` } })
}
export function UpdatePassword(token, uid, data) {
    return API.patch('/dashboard/agents/change-password/' + uid + '/', data, {
        headers: { Authorization: 'Token ' + token },
    })
}
export function ViewAgent(token, uid) {
    return API('/dashboard/agents/view/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
}
export function EditAgentAvatar(token, uid, data) {
    return API.patch('/dashboard/agents/edit/avatar/' + uid + '/', data, {
        headers: { Authorization: 'Token ' + token },
    })
}
// CHANNELS
export function GetChannels(token) {
    return API('/dashboard/channels/', { headers: { Authorization: 'Token ' + token } })
}
export function GetChannelsData(token) {
    return API('/dashboard/channels/data/', { headers: { Authorization: 'Token ' + token } })
}
export function NewChannel(token, data) {
    return API.post('/dashboard/channels/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function EditChannel(token, id, data) {
    return API.patch('/dashboard/channels/edit/' + id + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ReasignChannel(pk, data) {
    return API.patch(`/dashboard/channels/reasign/${pk}/`, data)
}
export function DeleteChannel(pk) {
    return API.delete(`/dashboard/channels/delete/${pk}/`)
}

// ROLES
export function GetRoles(qs) {
    return API(`/dashboard/roles/${qs ? `?${qs}` : ''}`)
}
export function NewRole(token, data) {
    return API.post('/dashboard/roles/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function EditRole(token, uid, data) {
    return API.patch('/dashboard/roles/edit/' + uid + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function UpdateCompany(token, uid, data) {
    return API.patch('/dashboard/companies/edit/' + uid + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function RegisterUser(data) {
    return API.post('/register/', data)
}
export function ReasignRole(token, pk, data) {
    return API.patch(`/dashboard/roles/reasign/${pk}/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function DeleteRole(token, pk) {
    return API.delete(`/dashboard/roles/delete/${pk}/`, { headers: { Authorization: 'Token ' + token } })
}
// TEAM API
export function GetTeams(token) {
    return API('/dashboard/team/', { headers: { Authorization: 'Token ' + token } })
}
export function NewTeam(token, data) {
    return API.post('/dashboard/team/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ReasignTeam(pk, data) {
    return API.patch(`/dashboard/team/reasign/${pk}/`, data)
}
export function DeleteTeam(pk) {
    return API.delete(`/dashboard/team/delete/${pk}/`)
}
export function EditTeam(token, id, data) {
    return API.patch('/dashboard/team/edit/' + id + '/', data, { headers: { Authorization: 'Token ' + token } })
}

// TAGS API
export function FilterTags(token, qs) {
    return API(`/tags/${qs ? `?${qs}` : ''}`, { headers: { Authorization: 'Token ' + token } })
}
export function RetrieveEditTag(token, uid, data) {
    if (data) {
        return API.patch(`/tags/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
    } else {
        return API(`/tags/${uid}`, data, { headers: { Authorization: 'Token ' + token } })
    }
}
export function AddNewTag(token, data) {
    return API.post(`/tags/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function ReasignTag(id, data) {
    return API.patch('/tags/' + id + '/reasign/', data)
}
export function DeleteTag(id) {
    return API.delete(`/tags/${id}/`)
}
// INVITATIONS API
export function ViewInviteToCompany(uid) {
    return API(`/invitations/view/${uid}/`)
}
export function InviteTeamAPI(token, data) {
    return API.post(`/invitations/send/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function AcceptInvitationToCompany(data) {
    return API.post(`/invitations/accept/`, data)
}

// GENERATE NEW TOKEN API
export function GenerateToken() {
    return API(`/generate-token/`)
}

// GENERATE NEW GOOGLE CREDENTIALS

export function SaveGoogleCredentials(token, data) {
    return API.post(`/google_oauth2_callback/`, data, { headers: { Authorization: 'Token ' + token } })
}

export function GetGoogleCredentialStatus(token) {
    return API(`/google_auth/?check=1`, { headers: { Authorization: 'Token ' + token } })
}

export function RevokeGoogleCredentials(token) {
    return API.delete(`/google_auth_revoke/`, { headers: { Authorization: 'Token ' + token } })
}

export function SyncContacts(token) {
    return API.get(`/google_sync/?sync_type=contacts`, { headers: { Authorization: 'Token ' + token } })
}

// // COMPANY ENDPOINTS
// export function RetrieveCompany(uid) {
//     return API.get('/dashboard/company/' + uid + '/')
// }

// export function UpdateCompanyV2(uid, data) {
//     return API.patch('/dashboard/company/' + uid + '/', data)
// }

export function CheckLocation(data) {
    return API.post(`/check-locations/`, data)
}
export function ViewBrandAPI(domain) {
    return API('/brand/', { headers: { domain: domain } })
}
export function OnboardingAPI(token) {
    return API('/onboarding/', { headers: { Authorization: 'Token ' + token } })
}

// Referral endpoints
export function ResumeRefer() {
    return API('/referral/accounts/resume/')
}

export function RetriveCodes(params) {
    return API(`/referral/?${params}`)
}

export function ListAccounts() {
    return API('/referral/accounts/')
}

export function SendInvite(data) {
    return API.post('/referral/invite/', data)
}

export function CheckLinkCSV(data, token) {
    return APISocket.post('/check-links/', data, { responseType: 'stream' })
}



export function RetrieveUserIntranet(uid) {
    return IntranetAPI(`users/${uid}/`)
}
export function CreateSector(data) {
    return IntranetAPI.post(`sectors/`, data)
}