import queryString from 'query-string'
import { API } from '../utils/config'

export function GetContacts(token) {
	// GET ALL CONTACTS
	return API('/contacts/', { headers: { Authorization: 'Token ' + token } })
}
export function GetContact(id, token) {
	// GET CONTACT WITH FULL SERIALIZER
	return API('/contacts/view/' + id + '/', { headers: { Authorization: 'Token ' + token } })
}
export function MergeContact(token, data) {
	return API.patch('/contacts/merge/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ViewContact(id, token) {
	// VIEW CONTACT WITH CLEAN SERIALIZER
	return API('/contacts/edit/' + id + '/', { headers: { Authorization: 'Token ' + token } })
}
export function DeleteContact(uid) {
	// DELETE CONTACTS
	return API.delete('/contacts/delete/' + uid + '/')
}
export function EditContact(id, data, token) {
	// UPDATE CONTACT WITH CLEAN SERIALIZER
	return API.patch('/contacts/edit/' + id + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function EditSelectedContacts(data) {
	return API.post('/contacts/edit/all/', data)
}
export function GetRecommendations(token, contact) {
	let query =
		'budget_min=' + contact.budget_min + '&budget_max=' + contact.budget_max + '&category=' + contact.interested_in
	let sector = ''
	sector = queryString.stringify({ sector: contact.sectors_interested })
	return API('/properties/recommended/?' + query + '&' + sector, { headers: { Authorization: 'Token ' + token } })
}
export function FilterContacts(token, sort) {
	return API('/contacts/?' + sort, { headers: { Authorization: 'Token ' + token } })
}
export function CreateContact(data, token) {
	return API.post('/contacts/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function NewPhone(data, token) {
	return API.post('/contacts/phones/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function DeletePhone(id, token) {
	return API.delete('/contacts/phones/delete/' + id + '/', { headers: { Authorization: 'Token ' + token } })
}
export function NewAddress(data, token) {
	return API.post('/contacts/address/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function GetContactTypes(qs) {
	return API(`/contacts/ctypes/${qs ? qs : ''}`)
}
export function NewImportantDate(token, data) {
	return API.post('/contacts/date/new/', data, { headers: { Authorization: 'Token ' + token } })
}
// -------------------------------
//          TESTIMONIOS
// -------------------------------
export function FilterTestimonials(token, qs) {
	return API(`/contacts/dashboard/testimonials/${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function SaveTestimonial(token, id, data) {
	return API.patch(`/contacts/dashboard/testimonials/edit/${id}/`, data, {
		headers: { Authorization: 'Token ' + token },
	})
}

export function CheckContacts(data) {
	return API.post(`/contacts/check/`, data)
}
