import React from 'react'
import { Spinner } from '@blueprintjs/core'
import styled, { css } from 'styled-components'

export default function ThSort(props) {
	return (
		<Th
			data-tip={props.tooltip}
			data-for={props.id}
			id={props.id}
			onClick={props.onClick}
			className={
				props.sort === props.id || props.sort === props.mid
					? 'sortable active ' + props.className
					: 'sortable ' + props.className
			}
			style={props.style}>
			<div id={props.id} data-tip={props.tooltip}>
				{props.label}{' '}
				{props.loading && (props.sort === props.id) | (props.sort === props.mid) ? (
					<span>
						<Spinner size={13} />
					</span>
				) : null}
				{props.chevronPosition && (
					<Chevron position={props.chevronPosition}>
						{props.sort?.replace('-', '') === props.id && (
							<>
								{props.sort.includes('-') ? (
									<i className="fas fa-caret-up"></i>
								) : (
									<i className="fas fa-caret-down"></i>
								)}
							</>
						)}
					</Chevron>
				)}
			</div>
		</Th>
	)
}
const Th = styled.th`
	position: relative;
`
const Chevron = styled.div`
	position: absolute;
	${(props) =>
		props.position === 'left' &&
		css`
			left: 5%;
			top: 25%;
			font-size: 12px;
		`}
	${(props) =>
		props.position === 'right' &&
		css`
			right: 5%;
			top: 25%;
			font-size: 12px;
		`}
`
// PROPS
// label = string
// id = ID
// mid = minus ID
