import { API } from '../utils/config'

export function GetProposals(token) {
    return API('/proposals/dashboard/', { headers: { Authorization: 'Token ' + token } })
}
export function GetAllProposals(token, params) {
    return API('/proposals/dashboard/all/' + params, { headers: { Authorization: 'Token ' + token } })
}
export function FilterProposals(token, qs) {
    return API('/proposals/dashboard/?' + qs, { headers: { Authorization: 'Token ' + token } })
}
export function CreateProposal(token, data) {
    return API.post('/proposals/dashboard/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ViewProposal(token, uid) {
    return API('/proposals/dashboard/view/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
}
export function CloneProposal(token, uid) {
    return API('/proposals/dashboard/clone/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
}
export function EditProposalApi(token, id, data) {
    if (data) {
        return API.patch('/proposals/dashboard/edit/' + id + '/', data, {
            headers: { Authorization: 'Token ' + token },
        })
    }
    return API('/proposals/dashboard/edit/' + id + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function EditProposalSimple(token, id, data) {
    if (data) {
        return API.patch('/proposals/dashboard/edit-simple/' + id + '/', data, {
            headers: { Authorization: 'Token ' + token },
        })
    }
    return API('/proposals/dashboard/edit/' + id + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ViewLogs(token, id) {
    return API('/proposals/dashboard/logs/' + id + '/', { headers: { Authorization: 'Token ' + token } })
}
export function DeleteProposalAPI(token, id) {
    return API.delete('/proposals/dashboard/delete/' + id + '/', { headers: { Authorization: 'Token ' + token } })
}
// add files to proposal
export function AddFilesProposal(token, uid, data) {
    return API.patch('/proposals/dashboard/files/' + uid + '/add', data, {
        headers: { Authorization: 'Token ' + token },
    })
}
