import mixpanel from 'mixpanel-browser'
mixpanel.init('c4d5bce3206cab4ecb557749946be7d5')

let env_check = process.env.NODE_ENV === 'production'
// let env_check = true

let actions = {
	identify: (id) => {
		if (env_check) mixpanel.identify(id)
	},
	alias: (id) => {
		if (env_check) mixpanel.alias(id)
	},
	track: (name, props) => {
		if (env_check) mixpanel.track(name, props)
	},
	set_group: (props) => {
		if (env_check) mixpanel.set_group(props)
	},
	people: {
		set: (props) => {
			if (env_check) mixpanel.people.set(props)
		},
		track_charge: (props) => {
			if (env_check) mixpanel.people.track_charge(props)
		},
	},
}

export let Mixpanel = actions
