import React from 'react'
import { Row, Col } from 'reactstrap'
import Help from '../elements/Help'

export default function TextArea(props) {
	return props.columns === 2 ? (
		<div className={props.sm ? 'form-group-sm' : 'form-group'}>
			<div className="row">
				<div className="col-xs-12 col-md-12 col-lg-4 text-right-lg">
					{props.label ? (
						<label htmlFor={props.id}>
							{props.label}: {props.required ? <span className="danger">*</span> : null}
						</label>
					) : null}
					{props.help ? <Help id={props.id} help={props.help} /> : null}
					{props.new && <span className="hot">NUEVO</span>}
				</div>
				<div className="col-xs-12 col-md-12 col-lg-6">
					<textarea
						placeholder={props.placeholder}
						type={props.type}
						name={props.id}
						disabled={props.disabled}
						id={props.id}
						className={props.className + ' form-control'}
						required={props.required}
						rows={props.rows}
						style={props.style}
						value={props.value !== null ? props.value : ''}
						onChange={props.onChange}
						onBlur={props.onBlur}
					/>
				</div>
			</div>
		</div>
	) : (
		<div className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			{props.new && <span className="hot">NUEVO</span>}
			<textarea
				placeholder={props.placeholder}
				type={props.type}
				name={props.id}
				disabled={props.disabled}
				id={props.id}
				className={props.className + ' form-control'}
				required={props.required}
				rows={props.rows}
				style={props.style}
				value={props.value !== null ? props.value : ''}
				onChange={props.onChange}
				onBlur={props.onBlur}
			/>
		</div>
	)
}
