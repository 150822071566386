import React from 'react'
import queryString from 'query-string'
import NumberFormat from 'react-number-format'
import { Input } from '../Components/FormGroups'

export function ReturnVals(index1, index2, data) {
	let arr = []
	let item = ''
	for (let x = 0; x < data.length; x++) {
		item = { label: data[x][index1], value: data[x][index2] }
		arr.push(item)
	}
	return arr
}
export function ReturnValue(index1, index2, data) {
	let val = {}
	if (data) {
		val.label = data[index1]
		val.value = data[index2]
	}
	return val
}
export function ReturnIntArray(options, state) {
	if (typeof options === 'string') {
		return [parseInt(options)]
	} else {
		if (options.length > 0) {
			let arr = []
			for (let x = 0; x < state.length; x++) {
				arr.push(parseInt(state[x]))
			}
			state = arr
		}
	}
	return state
}
export function ReturnIntArrayv2(options, state) {
	if (options.length > 0) {
		let arr = []
		for (let x = 0; x < state.length; x++) {
			arr.push(parseInt(state[x]))
		}
		state = arr
	}
	return state
}
export function ReturnValueTriple(index1, index2, index3, data) {
	let val = {}
	val.label = data[index1] + ' ' + data[index2]
	val.value = data[index3]
	return val
}
export function ReturnTeamMembers(team, data) {
	let arr = []
	for (let x = 0; x < data.length; x++) {
		if (team === data[x].team) {
			arr.push(data[x])
		}
	}
	return arr
}
export function DealsRecommendations(data) {
	let qsp = {}
	if (data.condition) qsp.condition = data.condition
	if (data.currency) qsp.currency = data.currency
	if (data.amenities) qsp.amenities = data.amenities
	if (data.rooms) qsp.rooms_min = data.rooms
	if (data.bathrooms) qsp.bath_min = data.bathrooms
	if (data.parkings) qsp.parking_min = data.parkings

	if (data.listing) {
		qsp.listing_type = data.listing
		let calc = parseInt((data.budget * 0.2).toFixed(0)) // 20% of Budget
		qsp.value_min = data.budget - calc
		qsp.value_max = data.budget + calc
		if (data.listing === 1 || data.listing === '1') {
			qsp.sort = 'us_saleprice'
		}
		if (data.listing === 2 || data.listing === '2') {
			qsp.sort = 'us_rentprice'
		}
		if (data.listing === 3 || data.listing === '3') {
			qsp.sort = 'us_rentalprice'
		}
		if (data.listing === 4 || data.listing === '4') {
			qsp.sort = 'us_furnished'
		}
	}
	if (qsp.value_min === 0 || data.value_min === '0') qsp.value_min = ''
	if (qsp.value_max === 0 || data.value_max === '0') qsp.value_max = ''
	if (data.sectors) {
		let arr = []
		for (let x = 0; x < data.sectors.length; x++) {
			arr.push(`${data.sectors[x].id}s`)
		}
		qsp.location = arr
	}
	if (data.categories) {
		let arr = []
		for (let x = 0; x < data.categories.length; x++) {
			arr.push(data.categories[x].id)
		}
		qsp.categories = arr
	}
	let context = {
		parsed: queryString.stringify(qsp),
		obj: qsp,
	}
	return context
}
export function NewDistinctID() {
	return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
}

export function RenderPrice(props) {
	const { currency, value } = props
	switch (currency) {
		case 'RD':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix={`RD$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		case 'DOP':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix={`RD$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		case 'US':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix={`US$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		case 'USD':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix={`US$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		case 'MXN':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix="$"
					suffix=" MXN"
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		case 'COP':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix="$"
					thousandSeparator="."
					decimalSeparator=","
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		case 'CRC':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix="₡"
					suffix=" CRC"
					thousandSeparator="."
					decimalSeparator=","
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		case 'GTQ':
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix="Q "
					suffix=""
					thousandSeparator="."
					decimalSeparator=","
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
		default:
			return (
				<NumberFormat
					{...props}
					decimalScale={2}
					prefix={`$ `}
					thousandSeparator={true}
					displayType={'text'}
					value={value}
					className={props.className ? props.className : ''}
					style={props.style}
				/>
			)
	}
}
export function RenderInputPrice(props) {
	const { currency } = props
	switch (currency) {
		case 'RD':
			return (
				<Input
					{...props}
					formattedVal
					thousandSeparator={true}
					prefix={`RD$ `}
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		case 'DOP':
			return (
				<Input
					{...props}
					formattedVal
					thousandSeparator={true}
					prefix={`RD$ `}
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		case 'US':
			return (
				<Input
					{...props}
					formattedVal
					thousandSeparator={true}
					prefix={`US$ `}
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		case 'USD':
			return (
				<Input
					{...props}
					formattedVal
					thousandSeparator={true}
					prefix={`US$ `}
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		case 'MXN':
			return (
				<Input
					{...props}
					formattedVal
					thousandSeparator={true}
					prefix="$"
					suffix=" MXN"
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		case 'COP':
			return (
				<Input
					{...props}
					formattedVal
					prefix="$"
					suffix=" COP"
					thousandSeparator="."
					decimalSeparator=","
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		case 'CRC':
			return (
				<Input
					{...props}
					formattedVal
					prefix="$"
					suffix=" CRC"
					thousandSeparator="."
					decimalSeparator=","
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		case 'GTQ':
			return (
				<Input
					{...props}
					formattedVal
					prefix="Q "
					suffix=""
					thousandSeparator="."
					decimalSeparator=","
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
		default:
			return (
				<Input
					{...props}
					formattedVal
					prefix={`$ `}
					thousandSeparator={true}
					// Props
					required={props.required}
					sm={props.sm}
					label={props.label}
					id={props.id}
					value={props.value}
					className={props.className}
					onValueChange={props.onValueChange}
					placeholder={props.placeholder}
					onChange={props.onChange}
					style={props.style}
					onBlur={props.onBlur}
					disabled={props.disabled}
				/>
			)
	}
}
export const formatPropertyUnitLabel = ({
	status,
	status_string,
	project_model,
	cid,
	children_id,
	room,
	bathroom,
	parkinglot,
	currency_sale,
	sale_price,
}) => (
	<div>
		<span className="d-block">
			{`${project_model ? project_model : `(${cid}${children_id ? `-${children_id}` : ''})`}`} | {status_string}
		</span>
		<small className="low-opacity">
			<ul className="react-select-ul">
				{room && (
					<li>
						<span className="card__property-ammenities-bed"></span> {room}
					</li>
				)}
				{bathroom && (
					<li>
						<i className="fas fa-bath"></i> {bathroom}
					</li>
				)}
				{parkinglot && (
					<li>
						<i className="fas fa-car"></i> {parkinglot}
					</li>
				)}
				<li>
					<RenderPrice currency={currency_sale} value={sale_price} />
				</li>
			</ul>
		</small>
	</div>
)
