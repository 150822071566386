import React, { Component } from 'react'
import Cookies from 'universal-cookie'
// Local imports
import Sidebar from './Layouts/Sidebar'
import Content from './Layouts/Content'
import Topbar from './Layouts/Topbar'
import { UserContext } from './context/UserProvider'
const cookies = new Cookies()

function AppContainer(props) {
	const context = React.useContext(UserContext)
	const updateContextVal = context.updateContextVal
	const { collapseSidebar } = context.state

	React.useEffect(() => {
		let status = cookies.get('toggle')
		let state = Object.assign({}, context.state)
		if (status === 'true') status = true
		if (status === 'false') status = false
		state.collapseSidebar = status
		updateContextVal(state)
	}, [])

	const _handleCollapse = () => {
		cookies.set('toggle', !collapseSidebar, {
			path: '/',
		})
		let state = Object.assign({}, context.state)
		state.collapseSidebar = !collapseSidebar
		updateContextVal(state)
	}

	let { showBars, user } = props
	return (
		<div id="master_container">
			{showBars && <Sidebar collapse={collapseSidebar} handleCollapse={_handleCollapse} />}
			{showBars && (
				<div className={collapseSidebar ? 'outer-wrapper collapse-outer-wrapper' : 'outer-wrapper'}>
					{showBars && <Topbar user={user} />}
					<Content showBars={showBars} user={user} />
				</div>
			)}
			{!showBars && <Content showBars={showBars} user={user} />}
		</div>
	)
}
export default AppContainer
