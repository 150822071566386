import React, { useEffect, useState } from 'react'
import Help from '../../elements/Help'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import styled from 'styled-components'
import { localization, settingsToolbar } from './config'
import { RichTextCleaner } from './textCleaner'
export default function EditorHTML(props) {
	const [value, setValue] = useState('')
	const [edited, setEdited] = useState(false)

	useEffect(() => {
		let defaultValue =
			props.defaultValue !== null && typeof props.defaultValue !== 'undefined' ? props.defaultValue : ''
		const contentBlock = htmlToDraft(defaultValue)
		if (contentBlock && !edited) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
			const editorState = EditorState.createWithContent(contentState)

			setValue(editorState)
		}
	}, [props.defaultValue])

	const handleChange = (val) => {
		setEdited(true)
		setValue(val)
		let value = draftToHtml(convertToRaw(val.getCurrentContent()))
		props.onChange(value)
	}

	return (
		<ContentEditor hideToolbar={props.hideToolbar} className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label className="control-label" htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			<Editor
				editorState={value}
				toolbarClassName="toolbarClassName"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={handleChange}
				localization={localization}
				toolbar={settingsToolbar}
				placeholder={props.placeholder}
				toolbarCustomButtons={[<RichTextCleaner />]}
			/>
		</ContentEditor>
	)
}

const ContentEditor = styled.div`
	.public-DraftEditorPlaceholder-inner {
		font-style: italic;
	}

	.rdw-editor-main {
		cursor: text;
		padding: 12px 15px;
		max-height: 550px;
		overflow-y: auto;
		min-height: 100px;
	}
	.public-DraftStyleDefault-block {
		margin: 0;
	}
	.toolbarClassName {
		${(props) => props.hideToolbar && `display:none;`}
		.rdw-option-wrapper:hover, .rdw-dropdown-wrapper:hover,.rdw-option-active:hover {
			border: none !important;
			box-shadow: none !important;
		}
		.rdw-option-wrapper,
		.rdw-dropdown-wrapper,
		.rdw-option-active {
			box-shadow: none !important;
			border: none !important;
		}
	}
`
