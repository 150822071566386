import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { Alert } from 'reactstrap'
import { Button } from 'tabler-react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { UserContext } from '../../context/UserProvider'

// Local import
import { withContext } from '../../context/withContext'
import { Input } from '../../Components/FormGroups'
import { API, updateHeaders } from '../../utils/config'
import { LoginAndTokenData } from '../../helpers/essentials'

const cookies = new Cookies()

function Login(props) {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [brokerModal, toggleBrokerModal] = useState(false)
	const [errData, setErrData] = useState({})
	const [alert, showAlert] = useState(false)
	const [loading, showLoading] = useState(false)
	const navigate = useNavigate()
	const brand = props.context.brand

	const handleLogin = (e) => {
		e.preventDefault()
		showAlert(false)
		showLoading(true)
		API.post('/intranet/login/', { username: username.replace(' ', ''), password: password })
			.then((response) => {
				cookies.set('toggle', false, { path: '/' })
				cookies.set('token', response.data.token, { path: '/' })
				cookies.set('company', response.data.company.company.uid, {
					path: '/',
				})
				let newData = LoginAndTokenData(response, 'Login')
				updateHeaders({ Authorization: `Token ${response.data.token}` })
				props.updateContextVal(newData)
				showLoading(false)
				navigate('/')
			})
			.catch((err) => {
				let errorData = err.response?.data
				try {
					showLoading(false)
					if (err.response?.data?.user_type === '3' || err.response?.data?.user_type === '5') {
						toggleBrokerModal(true)
					} else {
						setErrData(errorData.res)
						showAlert(true)
					}
				} catch (error) {
					console.log(error)
				}
			})
	}
	return (
		<div className="page">
			<MainContainer className="page-single" color={brand.primary_color}>
				<div className="container">
					<div className="row">
						<div className="col col-login mx-auto">
							<div className="text-center mb-6">
								<img
									src={brand.logo}
									style={{ paddingTop: '1rem', maxHeight: '80px' }}
									alt={`${brand.name} logo`}
								/>
							</div>
							<form className="card" onSubmit={handleLogin}>
								<div className="card-body p-6">
									<div className="card-title">Inciar Sesión</div>
									<Input
										required
										type="text"
										label="Email"
										id="username"
										placeholder="Correo electrónico"
										value={username}
										onChange={(e) => setUsername(e.target.value.toLowerCase().replace(' ', ''))}
									/>
									<Input
										required
										type="password"
										label="Contraseña"
										id="password"
										placeholder="Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<div className="form-footer">
										<Button loading={loading} color="primary" className="btn-block" type="submit">
											Acceder
										</Button>
										<center className="mt-3">
											<Link to="/olvide-contrasena">Olvidé mi contraseña</Link>
										</center>
									</div>
									{alert && (
										<Alert color="danger" className="mt-4">
											<center>{errData}</center>
										</Alert>
									)}
								</div>
							</form>
							<center className="mb-3">
								<Support id="request_support" onClick={() => window.Intercom('show')}>
									<span>
										<i className="far fa-life-ring"></i> Solicitar Ayuda
									</span>
								</Support>
							</center>
						</div>
					</div>
				</div>
			</MainContainer>
			<Modal isOpen={brokerModal} toggle={() => toggleBrokerModal(false)}>
				<ModalHeader toggle={() => toggleBrokerModal(false)}>Modal title</ModalHeader>
				<ModalBody>
					<ModalContainer>
						<div className="alert alert-warning fade show" role="alert">
							<center>
								<strong>AVISO IMPORTANTE</strong>
							</center>
						</div>
						<p>
							La cuenta con la cual estás intentando acceder, es una cuenta de Broker Externo por lo tanto
							debes de acceder al Área de Brokers hacienco{' '}
							<a
								href="https://brokers.alterestate.com/login?utm_referer=Dashboard"
								target="_blank"
								rel="noopener noreferrer">
								click aquí
							</a>
							, esta parte de la plataforma es exclusiva para clientes de AlterEstate.
						</p>
						<a
							href="https://brokers.alterestate.com/login?utm_referer=Dashboard"
							target="_blank"
							rel="noopener noreferrer">
							<button className="btn btn-success w-100" disabled="">
								Ir al Área de Brokers
							</button>
						</a>
						<center>
							<Link to="/registro">o registrate en AlterEstate - CRM Inmobiliario</Link>
						</center>
					</ModalContainer>
				</ModalBody>
			</Modal>
		</div>
	)
}
const ModalContainer = styled.div`
	button {
		font-size: 18px;
		font-family: 'Rubik', sans-serif;
		margin-bottom: 15px;
	}
	button.btn-success {
		background-color: #08a742 !important;
		border-color: #08a742 !important;
		:hover {
			opacity: 0.7;
		}
	}
`
const MainContainer = styled.div`
	.btn-primary {
		background-color: ${(props) => props.color};
		border-color: ${(props) => props.color};
		:hover {
			opacity: 0.7;
		}
	}
`
const Support = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	margin-right: 8px;
	cursor: pointer;
	background: #fff;
	border-color: #fff;
	border: 0;
	min-height: 33px;
	transition: all 0.3s;
	border-radius: 3px;
	i {
		margin-right: 5px;
		color: #457ecf;
	}
	:hover {
		background: #e6e6e6;
	}
`
export default withContext(Login)
