import { IntranetAPI } from '../utils/config'

// -----------------------------------------
// ------------- INTRANET ------------------
// -----------------------------------------
export function CompanyAPI(uid, data) {
	if (data) {
		return IntranetAPI.patch(`/companies/${uid}/`, data)
	} else {
		return IntranetAPI.get(`/companies/${uid}/`)
	}
}
export function FilterCompanies(qs) {
	return IntranetAPI.get(`/companies/${qs ? `?${qs}` : ''}`)
}
export function ChangeDataCompany(data, uid) {
	return IntranetAPI.patch(`/companies/${uid}/`, data)
}
export function GetProperty(uid) {
	return IntranetAPI.get(`/properties/${uid}/`)
}
export function SearchProperties(value) {
	return IntranetAPI.get(`/properties/?search=${value}`)
}
export function GetProperties() {
	return IntranetAPI.get(`/properties`)
}
export function SortTableUsers(page, value) {
	return IntranetAPI.get(`/users/?page=${page}&sort=${value.id}`)
}
export function ListUsers(params) {
	return IntranetAPI.get(`/users/${params ? `?${params}` : ''}`)
}
export function SortProperties(page, value) {
	return IntranetAPI.get(`/properties/?page=${page}&sort=${value}`)
}
export function ChangeUserCompany(data, uid) {
	return IntranetAPI.patch(`/users/${uid}/`, data)
}
export function ListReportLogs(params) {
	return IntranetAPI.get(`properties/reports/?${params}`)
}
export function FilterProperties(qs) {
	return IntranetAPI.get('/properties/' + qs, {})
}
export function FilterRolesAPI(qs) {
	return IntranetAPI.get(`/roles/${qs ? `?${qs}` : ''}`)
}
export function CompanyConfigAPI(pk, data) {
	if (data) {
		return IntranetAPI.patch(`/config/${pk}/`, data)
	} else {
		return IntranetAPI.get(`/config/${pk}/`)
	}
}
export function DeleteCompanyAPI(uid) {
	return IntranetAPI.get(`/company/delete/${uid}/`)
}
export function UpdateBankRatesAPI(id, data) {
	return IntranetAPI.patch(`/update-banks/${id}/`, data)
}
export function FilterContactTypesAPI(qs) {
	return IntranetAPI.get(`/contacts/ctypes/${qs ? `?${qs}` : ''}`)
}
export function FilterSitesAPI(qs) {
	return IntranetAPI.get(`/cms/sites/${qs ? `?${qs}` : ''}`)
}
export function FilterThemesAPI(qs) {
	return IntranetAPI.get(`/cms/themes/${qs ? `?${qs}` : ''}`)
}
export function SiteAPI(id, data) {
	if (id && data) {
		return IntranetAPI.patch(`/cms/sites/${id}/`, data)
	}
	if (!id && data) {
		return IntranetAPI.post(`/cms/sites/`, data)
	}
	return IntranetAPI.get(`/cms/sites/${id}/`)
}
export function RoundRobinsAPI(qs) {
	return IntranetAPI.get(`/campaigns/round-robins/${qs}`)
}
