import React from 'react'
import styled, { css } from 'styled-components'

export default function OptionBtn(props) {
    return (
        <Button {...props}>
            {props.children}
            {props.showNextIcon && <i className="fas fa-chevron-right"></i>}
        </Button>
    )
}
const Button = styled.button`
    display: block;
    text-align: center;
    font-weight: 400;
    color: rgb(255, 255, 255);
    font-size: 19px;
    width: 100%;
    position: relative;
    margin-bottom: 16px;
    text-transform: none;
    letter-spacing: 0.02em;
    border-top: none;
    border-left: none;
    border-right: none;
    overflow: hidden;
    border-radius: 4px;
    padding: 13px;
    background: #0e4d9b;
    border-bottom: 3px solid rgb(0, 32, 76);
    transition: background-color 0.5s ease-out 0s;
    font-weight: 700;
    :hover {
        background-color: rgb(2, 92, 216);
        color: rgb(255, 255, 255);
        top: 2px;
    }
    :active {
        top: 7px;
        background-image: none;
        box-shadow: rgba(0, 0, 0, 0.125) 0px 3px 5px inset;
        outline: 0px;
    }
    i {
        position: absolute;
        right: 15px;
        top: 21px;
    }
    ${(props) =>
        props.color &&
        props.color !== '' &&
        css`
            background: ${(props) => props.color};
            border-color: #000;
            :hover {
                background-color: ${(props) => props.color};
                opacity: 0.8;
            }
        `}
`
