import React from 'react'
import styled from 'styled-components'
import { DebounceInput } from 'react-debounce-input'
import { Spinner } from '@blueprintjs/core'

export default function TextFilter(props) {
	return (
		<Container className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label className="control-label" htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			<div className="input-icon">
				<DebounceInput
					{...props}
					id={props.id}
					loading={undefined}
					placeholder={props.placeholder}
					minLength={props.minLength ? props.minLength : 3}
					debounceTimeout={1000}
					className={props.className}
					style={props.style}
					value={props.value}
					onChange={props.onChange}
				/>
				{!props.noIcon && (
					<span className="input-icon-addon">
						{props.loading ? <Spinner size={20} /> : <i className="fas fa-search"></i>}
					</span>
				)}
				{props.invalidMsg !== '' && <span className="invalid-feedback">{props.invalidMsg}</span>}
			</div>
		</Container>
	)
}
const Container = styled.div`
	.input-icon-addon {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		padding-right: 10px;
	}
`
