import Axios from 'axios'
import { API, API_GOOGLE } from '../utils/config'

export function GetPropertiesIDs(token) {
    return API('/properties/ids/', { headers: { Authorization: 'Token ' + token } })
}
export function FastSearchProperties(qs, token) {
    return API('/properties/dashboard/fastsearch/?search=' + qs, { headers: { Authorization: 'Token ' + token } })
}
export function FilterProperties(token, qs) {
    return API('/properties/dashboard/filter/' + qs, { headers: { Authorization: 'Token ' + token } })
}
export function FilterPropertiesByUID(token, data) {
    return API.post('/properties/dashboard/filter/uid/', data, { headers: { Authorization: 'Token ' + token } })
}
export function FilterPropertiesCode(token, qs) {
    return API('/properties/dashboard/filter/cid/' + qs, { headers: { Authorization: 'Token ' + token } })
}
export function SubmitProperty(token, data) {
    return API.post('/properties/create/', data, { headers: { Authorization: 'Token ' + token } })
}
// UNITS
export function NewUnitProperty(token, data) {
    return API.post('/properties/units/new/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ViewProjectAvailability(token, uid, query) {
    return API(`/properties/units/all/${uid}/${query ? `?${query}` : ''}`, {
        headers: { Authorization: 'Token ' + token },
    })
}
export function ViewProjectAvailabilityPublic(uid) {
    return API(`/properties/public/units/${uid}/`)
}
export function EditUnitProperty(token, uid, data) {
    return API.patch(`/properties/units/edit/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function ReorderUnits(token, data) {
    return API.patch(`/properties/units/reorder/`, data, { headers: { Authorization: 'Token ' + token } })
}
// end
export function DuplicateProperty(token, data) {
    return API.post('/properties/clone/', data, { headers: { Authorization: 'Token ' + token } })
}
export function FilterAmenities(token, qs) {
    return API('/properties/features/?' + qs, { headers: { Authorization: 'Token ' + token } })
}
export function GetProperty(token, slug) {
    return API('/properties/dashboard/view/' + slug + '/', { headers: { Authorization: 'Token ' + token } })
}
export function ViewPropertyDetails(token, uid) {
    return API('/properties/dashboard/view/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
}
export function ViewPropertyQuick(token, uid) {
    return API('/properties/dashboard/view/' + uid + '/quick/', { headers: { Authorization: 'Token ' + token } })
}
export function EditProperty(token, uid, data) {
    if (data) {
        return API.patch('/properties/dashboard/edit/' + uid + '/', data, {
            headers: { Authorization: 'Token ' + token },
        })
    } else {
        return API('/properties/dashboard/edit/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
    }
}
export function DeleteProperty(token, uid) {
    return API.delete('/properties/dashboard/delete/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
}
/*--------------------------------
-       PHOTO GALLERY
----------------------------------*/
export function UploadGallery(token, data) {
    return API.post('/properties/dashboard/gallery/upload/', data, { headers: { Authorization: 'Token ' + token } })
}
export function UpdatePhotoGallery(token, data, uid) {
    return API.patch('/properties/dashboard/gallery/update/' + uid + '/', data, {
        headers: { Authorization: 'Token ' + token },
    })
}
export function ViewPhotoGallery(token, id) {
    return API('/properties/dashboard/gallery/' + id + '/', { headers: { Authorization: 'Token ' + token } })
}
export function DeletePhotoGallery(token, id) {
    return API.delete('/properties/dashboard/gallery/delete/' + id + '/', {
        headers: { Authorization: 'Token ' + token },
    })
}
export function FeaturedImageHandler(token, id, data, isProject) {
    if (isProject) {
        if (data) {
            // Update featured image
            return API.patch('/projects/photos/featured/' + id + '/', data, {
                headers: { Authorization: 'Token ' + token },
            })
        } else {
            // Get featured image
            return API('/projects/photos/featured/' + id + '/', {
                headers: { Authorization: 'Token ' + token },
            })
        }
    } else {
        if (data) {
            // Update featured image
            return API.patch('/properties/dashboard/gallery/featured/' + id + '/', data, {
                headers: { Authorization: 'Token ' + token },
            })
        } else {
            // Get featured image
            return API('/properties/dashboard/gallery/featured/' + id + '/', {
                headers: { Authorization: 'Token ' + token },
            })
        }
    }
}
export function FeaturedImageProject(token, id, data) {
    if (data) {
        // Update featured image
        return API.patch('/projects/photos/featured/' + id + '/', data, {
            headers: { Authorization: 'Token ' + token },
        })
    } else {
        // Get featured image
        return API('/projects/photos/featured/' + id + '/', {
            headers: { Authorization: 'Token ' + token },
        })
    }
}

export function EditSelectedProperties(data) {
    return API.post('/properties/dashboard/edit/all/', data)
}

export function GetLatLong(qs) {
    return Axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${qs}&key=${API_GOOGLE}`)
}
/*--------------------------------
-       SAVED_SEARCHES
----------------------------------*/
export function SavedSearches(token) {
    return API('/properties/saved-searches/', { headers: { Authorization: 'Token ' + token } })
}
export function SavedSearchesCreate(token, data) {
    return API.post('/properties/saved-searches/', data, { headers: { Authorization: 'Token ' + token } })
}
export function SavedSearchesUpdate(token, pk, data) {
    return API.patch(`/properties/saved-searches/${pk}/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function SavedSearchesDelete(token, pk) {
    return API.delete(`/properties/saved-searches/${pk}/`, { headers: { Authorization: 'Token ' + token } })
}
/*--------------------------------
-       SALE_RENT_DATA
----------------------------------*/
export function SaleRentDataCreate(token, data) {
    return API.post('/properties/register-property-change/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ChangeStats(slug, data) {
    return API.patch('/properties/stats/' + slug + '/', data)
}
/*--------------------------------
-    PUBLICAR PROPIEDAD EN ML 
----------------------------------*/
export function PublishPropertyML(data) {
    return API.post(`/integrations/mercadolibre/publish/`, data)
}

export function SyncPropertyExpMaxCore(data) {
    return API.post(`/integrations/property_import/`, data)
}
