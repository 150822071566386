import React, { useEffect } from 'react'
import styled from 'styled-components'
import LocationsTable from './components/LocationsTable'
import { ListSectorsIntranet, CreateSector } from '../../API/core'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { DebounceInput } from 'react-debounce-input'
import { SearchableCountries, SearchableProvinces, SearchableCities } from '../../Components/FormGroups'
import { OurToaster } from '../../Components/elements/Toast'

function Locations(props) {
    const [loading, setLoading] = React.useState(false)
    const [locations, setLocations] = React.useState([])
    const [search, setSearch] = React.useState("")
    const [nextParams, setNextParams] = React.useState("")
    const [modal, toggle] = React.useState(false)
    const [newLocation, setNewLocation] = React.useState({
        country: null,
        province: null,
        city: null,
        name: null,
    })

    const handleNewLocation = (value, index) => {
        let newLocation_ = Object.assign({}, newLocation)
        newLocation_[index] = value
        setNewLocation(newLocation_)
    }


    const initData = async (params, clear = false) => {
        let response = await ListSectorsIntranet(`?${params}`)
        let locations_ = clear ? [] : Array.from(locations)
        const result = locations_.concat(response.data.results)
        setLocations(result)

        if (response.data.next !== null) {
            setNextParams(response.data.next.split("?")[1])
        } else {
            setNextParams(null)
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        initData(`name=${e.target.value}`, true)
    }

    const handleCreateSector = async () => {
        setLoading(true)
        try {
            await CreateSector(newLocation)
            OurToaster.show({ message: 'Sector creado satisfactoriamente.', intent: 'success' })
            toggle(false)
        } catch (err) {
            OurToaster.show({ message: 'Ha ocurrido un error.', intent: 'danger' })
        }
        setLoading(false)
    }

    useEffect(() => { initData('') }, [])

    return <Container className='px-3'>
        <div className='card my-3 '>
            <div className='card-body'>

                <Row className=''>
                    <Col xs='12' sm='6' md='6' lg="6" xl='4' >
                        <DebounceInput
                            placeholder="Buscar..."
                            minLength={3}
                            debounceTimeout={500}
                            className="form-control"
                            value={search}
                            onChange={handleSearch}
                        />

                    </Col>
                    <Col className='text-right'>
                        <button onClick={() => toggle(true)} className='btn btn-success'>Crear sector</button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LocationsTable locations={locations} RetrieveData={initData} nextParams={nextParams} />
                    </Col>
                </Row>
            </div>
        </div>

        <Modal isOpen={modal} toggle={() => toggle(false)}>
            <ModalHeader>
                Crear nuevo sector
            </ModalHeader>
            <ModalBody>
                <label className='w-100'>
                    País
                    <SearchableCountries onChange={(value) => handleNewLocation(value, "country")} />
                </label>
                <label className='w-100'>
                    Provincia
                    <SearchableProvinces onChange={(value) => handleNewLocation(value, "province")} disabled={newLocation.country === null || newLocation.country === ""} params={`country=${newLocation.country}`} />
                </label>
                <label className='w-100'>
                    Ciudad
                    <SearchableCities onChange={(value) => handleNewLocation(value, "city")} disabled={newLocation.province === null || newLocation.province === ""} params={`province=${newLocation.province}`} />
                </label>
                <label className='w-100'>
                    Nuevo sector
                    <input className='form-control' onChange={(e) => handleNewLocation(e.target.value, "name")} disabled={newLocation.city === null || newLocation.city === ""} />
                </label>
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-secondary' onClick={() => toggle(false)} disabled={loading}>Cancelar</button>
                <button className='btn btn-primary' onClick={handleCreateSector} disabled={loading}>{loading ? <Spinner /> : "Guardar"} </button>
            </ModalFooter>
        </Modal>
    </Container >
}
const Container = styled.div``
export default Locations
