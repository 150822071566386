import React from 'react'
import Cookies from 'universal-cookie'
import { Routes, Route } from 'react-router-dom'
// Local imports
import AppLoader from './Components/AppLoader'
import Authentication from './Pages/Authentication'
import AppContainer from './AppContainer'
import { UserContext } from './context/UserProvider'

const cookies = new Cookies()

function Principal(props) {
	const context = React.useContext(UserContext)
	const { updateContextVal } = context
	const { settings } = context.state
	const [showBars, setshowBars] = React.useState(true)

	React.useEffect(() => {
		let status = cookies.get('toggle')
		let state = Object.assign({}, context.state)
		if (status === 'true') status = true
		if (status === 'false') status = false
		state.collapseSidebar = status
		updateContextVal(state)
	}, [])

	let isLogged = context.state.isLogged
	let loadingApp = context.state.loadingApp
	const company = isLogged ? settings.company : null
	return (
		<React.Fragment>
			{loadingApp ? (
				<AppLoader />
			) : isLogged ? (
				<React.Fragment>
					<Routes>
						<Route path="/*" element={<AppContainer showBars={showBars} />} />
					</Routes>
				</React.Fragment>
			) : (
				<Authentication />
			)}
		</React.Fragment>
	)
}
export default Principal
