import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'
import { withContext } from '../context/withContext'

function AppLoader(props) {
	return (
		<>
			<LoadingContainer>
				<img
					src="https://alterestate.s3.amazonaws.com/static/properties/AlterEstate/2019/6/logo-only.png"
					alt="Logo AlterEstate"
				/>
			</LoadingContainer>
			<SpinnerContainer>
				<Spinner size={200} intent="success" />
			</SpinnerContainer>
		</>
	)
}
const LoadingContainer = styled.div`
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 99%;
	img {
		height: 100px;
		padding-bottom: 5px;
	}
`
const SpinnerContainer = styled.div`
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	opacity: 0.5;
`
export default withContext(AppLoader)
