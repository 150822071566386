import { API } from '../utils/config'

// ROUND ROBINS
export function GetGoals(token, filters) {
    return API(`/goals/?${filters}`, { headers: { Authorization: 'Token ' + token } })
}

export function GetGoalObjectives(token) {
    return API(`/goals/objectives/`, { headers: { Authorization: 'Token ' + token } })
}

export function RetrieveGoal(token, uid) {
    return API(`/goals/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}

export function RemoveGoal(token, uid) {
    return API.delete(`/goals/${uid}/`, {
        headers: { Authorization: 'Token ' + token },
    })
}

export function UpdateGoal(token, uid, data) {
    return API.patch(`/goals/${uid}/`, data, {
        headers: { Authorization: 'Token ' + token },
    })
}

export function DuplicateGoal(token, uid) {
    return API.get(`/goals/${uid}/duplicate/`, {
        headers: { Authorization: 'Token ' + token },
    })
}

export function CreateGoal(token, data) {
    return API.post(`/goals/`, data, {
        headers: { Authorization: 'Token ' + token },
    })
}
