import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'
import Help from '../elements/Help'
import { withContext } from '../../context/withContext'
import { LocationsAPI, GetCountriesWithProperties } from '../../API/core'
import { Spinner, Intent } from '@blueprintjs/core'

function SearchableSectors(props) {
	const token = props.context.token
	const [loading] = useState(false)

	const [loadingAPI, setLoaderAPI] = useState(true)
	const [loadingCountries, setLoadingCountries] = useState(true)

	const [value, setValue] = useState([])
	const [countries, setCountries] = useState([])
	const [selected_country, setSelectedCountry] = useState(null)
	const [debounce, setDebounce] = useState(0)

	useEffect(() => {
		let arr = []
		if (typeof props.location === 'object') {
			for (let x = 0; x < props.location.length; x++) {
				arr.push(props.location[x])
			}
		}
		if (typeof props.location === 'string') {
			arr.push(props.location)
		}
		initData()
		RetrieveData(arr)
	}, [])

	const initData = async () => {
		let response = await GetCountriesWithProperties(token, `by=${props.origin}`)
		let countries = response.data
		let find = null
		if (props.filters.country === null) {
			setSelectedCountry(null)
		} else {
			if (countries.length == 1) {
				setSelectedCountry(countries[0])
			} else {
				find = countries.find((e) => e.id === parseInt(props.filters.country))
				if (typeof find !== 'undefined') {
					setSelectedCountry(find)
				} else {
					find = countries.find((e) => e.id === parseInt(props.context.settings.company.country))
					if (typeof find !== 'undefined') {
						setSelectedCountry(find)
					} else {
						setSelectedCountry(null)
					}
				}
			}
		}

		setCountries(countries)
		setLoadingCountries(false)
	}

	const RetrieveData = async (val) => {
		setLoaderAPI(true)
		try {
			let res = await LocationsAPI('', '', val)
			setLoaderAPI(false)
			setValue(res.data)
		} catch (error) {
			console.log(error)
		}
	}

	const handleChange = (element) => {
		let newData = Object.assign([], value)
		props.onChange(element)
		newData.push(element)
		setValue(newData)
	}

	const handleSelect = (index, element) => {
		// Actualizamos local y luego mandamos pa' arriba
		let newData = Object.assign([], value)
		newData.splice(index, 1)
		setValue(newData)
		return props.removeLocation(element)
	}

	const loadOptions = (inputValue, callback) => {
		let arr = []
		clearTimeout(debounce)
		setDebounce(
			setTimeout(() => {
				return LocationsAPI(inputValue, selected_country.id).then((response) => {
					for (let x = 0; x < response.data.length; x++) {
						let val = response.data[x]
						arr.push({
							value: `${val.data.id}${val.item_type}`,
							label: val.item_type === 'c' ? `${val.data.name}` : `${val.data.name}, ${val.data.city}`,
						})
					}
					let newArray = arr.sort((a, b) => {
						if (a.first_name > b.first_name) {
							return -1
						}
					})
					return callback(newArray)
				})
			}, 500)
		)
	}
	if (loadingCountries) {
		return (
			<div className="w-100 text-center pt-4">
				<Spinner size="16" intent={Intent.PRIMARY} />
			</div>
		)
	} else {
		return (
			<FilterContainer className={props.sm ? 'form-group-sm' : 'form-group'}>
				{countries.length > 1 && (
					<Row>
						<Col className="mb-2">
							<label className="w-100">
								País:
								<Select
									// isMulti={props.isMulti}
									isClearable={true}
									value={selected_country} // Adrede de esta manera
									options={countries}
									placeholder={<span>Selecciona un país</span>}
									onChange={(e) => {
										setSelectedCountry(e)
										props.handleCountry(e)
									}}
									classNamePrefix="react-select-2"
									closeMenuOnSelect={props.closeMenuOnSelect}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</label>
						</Col>
					</Row>
				)}
				{props.label ? (
					<label htmlFor={props.id}>
						{props.label}: {props.required ? <span className="danger">*</span> : null}
					</label>
				) : null}
				{props.help ? <Help id={props.id} help={props.help} /> : null}
				<AsyncSelect
					isMulti={props.isMulti}
					// value={null} // Adrede de esta manera
					loadOptions={loadOptions}
					isLoading={loading}
					placeholder={
						<span>
							<i className="fas fa-search"></i> Busca una o más ubicaciones
						</span>
					}
					isDisabled={loading || selected_country === null}
					onChange={(e) => handleChange(e)}
					classNamePrefix="react-select"
					noOptionsMessage={() => 'No encontramos resultados'}
					// Ops
					cacheOptions
					// autoFocus
					isClearable={false}
					openMenuOnClick={false}
					closeMenuOnSelect={false}
					onCloseResetsInput={false}
					onBlurResetsInput={false}
				/>
				{loadingAPI ? (
					'Cargando...'
				) : (
					<ResultsContainer>
						{typeof value === 'object' && value.length === 0 ? (
							<NoResultsFound>No has seleccionado ninguna ubicación</NoResultsFound>
						) : (
							<React.Fragment>
								<label>Seleccionados:</label>
								{value.map((e, key) => (
									<li key={key} onClick={() => handleSelect(key, e.value)}>
										<FeatherIcon size="16" icon="minus-circle" /> {e.label}
									</li>
								))}
							</React.Fragment>
						)}
					</ResultsContainer>
				)}
			</FilterContainer>
		)
	}
}
const FilterContainer = styled.div`
	.react-select__indicators {
		display: none !important;
	}
	.react-select-2__control {
		border-color: #e0e5ed;
		:hover {
			border-color: #e0e5ed;
		}
	}
`
const NoResultsFound = styled.div`
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.5;
`
const ResultsContainer = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		font-size: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 7px 15px 7px 5px;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		i,
		svg {
			margin-right: 5px;
			color: red;
			opacity: 0.6;
		}
		:hover {
			background: #f5f5f5;
		}
	}
	label {
		margin: 0;
		padding-top: 10px;
	}
`
export default withContext(SearchableSectors)
