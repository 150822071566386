import en from './en'

function Translate() {
	let en_ = en
	en_['components.controls.blocktype.h2'] = (
		<svg style={{ width: '16px' }} viewBox="0 0 18 18">
			{' '}
			<path
				class="ql-fill"
				d="M16.73975,13.81445v.43945a.54085.54085,0,0,1-.605.60547H11.855a.58392.58392,0,0,1-.64893-.60547V14.0127c0-2.90527,3.39941-3.42187,3.39941-4.55469a.77675.77675,0,0,0-.84717-.78125,1.17684,1.17684,0,0,0-.83594.38477c-.2749.26367-.561.374-.85791.13184l-.4292-.34082c-.30811-.24219-.38525-.51758-.1543-.81445a2.97155,2.97155,0,0,1,2.45361-1.17676,2.45393,2.45393,0,0,1,2.68408,2.40918c0,2.45312-3.1792,2.92676-3.27832,3.93848h2.79443A.54085.54085,0,0,1,16.73975,13.81445ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"></path>{' '}
		</svg>
	)
	en_['components.controls.blocktype.h1'] = (
		<svg style={{ width: '16px' }} viewBox="0 0 18 18">
			{' '}
			<path
				class="ql-fill"
				d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z"></path>{' '}
		</svg>
	)
	en_['components.controls.blocktype.blockquote'] = (
		<svg style={{ width: '16px' }} viewBox="0 0 18 18">
			{' '}
			<rect class="ql-fill ql-stroke" height="3" width="3" x="4" y="5"></rect>{' '}
			<rect class="ql-fill ql-stroke" height="3" width="3" x="11" y="5"></rect>{' '}
			<path class="ql-even ql-fill ql-stroke" d="M7,8c0,4.031-3,5-3,5"></path>{' '}
			<path class="ql-even ql-fill ql-stroke" d="M14,8c0,4.031-3,5-3,5"></path>{' '}
		</svg>
	)
	let localization_ = {
		locale: 'en',
		translations: en,
	}
	return localization_
}

export const localization = Translate()

export const settingsToolbar = {
	options: ['inline', 'list'],
	blockType: {
		inDropdown: false,
		options: ['H1', 'H2', 'Blockquote'],
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
	},
	inline: {
		inDropdown: false,
		options: ['bold', 'italic', 'underline', 'strikethrough'],
		bold: {
			icon: 'https://img.icons8.com/material/16/000000/bold.png',
			className: undefined,
		},
		underline: {
			icon: 'https://img.icons8.com/fluency-systems-regular/16/000000/underline.png',
			className: undefined,
		},
		italic: {
			icon: 'https://img.icons8.com/fluency-systems-regular/16/000000/italic.png',
			className: undefined,
		},
		strikethrough: {
			icon: 'https://img.icons8.com/fluency-systems-regular/16/000000/strikethrough.png',
			className: undefined,
		},
	},
	fontFamily: { inDropdown: false, options: ['sans-serif', 'serif', 'monospace'] },
	list: { inDropdown: false },
	textAlign: { inDropdown: true },
	link: { inDropdown: true },
	history: { inDropdown: true },
	link: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		popupClassName: undefined,
		dropdownClassName: undefined,
		showOpenOptionOnHover: true,
		defaultTargetOption: '_self',
		options: ['link'],
		// link: { icon: link, className: undefined },
		// unlink: { icon: unlink, className: undefined },
		linkCallback: undefined,
	},
	embedded: {
		// icon: embedded,
		className: undefined,
		component: undefined,
		popupClassName: undefined,
		embedCallback: undefined,
		defaultSize: {
			height: 'auto',
			width: 'auto',
		},
	},
}

// export const settingsToolbar = { // BACKUP de settings descomenta para restaurar el editor
// 	options: ['blockType', 'textAlign', 'fontFamily', 'inline', 'list', 'link', 'embedded'],
// 	blockType: {
// 		inDropdown: false,
// 		options: ['H1', 'H2', 'Blockquote'],
// 		className: undefined,
// 		component: undefined,
// 		dropdownClassName: undefined,
// 	},
// 	inline: {
// 		inDropdown: false,
// 		options: ['bold', 'italic', 'underline', 'strikethrough'],
// 		bold: {
// 			icon: 'https://img.icons8.com/material/16/000000/bold.png',
// 			className: undefined,
// 		},
// 		underline: {
// 			icon: 'https://img.icons8.com/fluency-systems-regular/16/000000/underline.png',
// 			className: undefined,
// 		},
// 		italic: {
// 			icon: 'https://img.icons8.com/fluency-systems-regular/16/000000/italic.png',
// 			className: undefined,
// 		},
// 		strikethrough: {
// 			icon: 'https://img.icons8.com/fluency-systems-regular/16/000000/strikethrough.png',
// 			className: undefined,
// 		},
// 	},
// 	fontFamily: { inDropdown: false, options: ['sans-serif', 'serif', 'monospace'] },
// 	list: { inDropdown: false },
// 	textAlign: { inDropdown: true },
// 	link: { inDropdown: true },
// 	history: { inDropdown: true },
// 	link: {
// 		inDropdown: false,
// 		className: undefined,
// 		component: undefined,
// 		popupClassName: undefined,
// 		dropdownClassName: undefined,
// 		showOpenOptionOnHover: true,
// 		defaultTargetOption: '_self',
// 		options: ['link'],
// 		// link: { icon: link, className: undefined },
// 		// unlink: { icon: unlink, className: undefined },
// 		linkCallback: undefined,
// 	},
// 	embedded: {
// 		// icon: embedded,
// 		className: undefined,
// 		component: undefined,
// 		popupClassName: undefined,
// 		embedCallback: undefined,
// 		defaultSize: {
// 			height: 'auto',
// 			width: 'auto',
// 		},
// 	},
// }
