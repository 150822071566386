import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { ValidateToken, ViewBrandAPI } from '../API/core'
import { updateHeaders } from '../utils/config'
import Confetti from 'react-confetti'
import { LoginAndTokenData } from '../helpers/essentials'

const cookies = new Cookies()

export const UserContext = React.createContext()

function UserProvider(props) {
	const [confetti, toggleConfetti] = useState(false)
	const [state, setState] = React.useState({
		token: cookies.get('token'),
		company: cookies.get('company'),
		collapseSidebar: cookies.get('toggle'),
		isLogged: false,
		confetti: false,
		language: 'es',
		brand: {},
		size: 1280,
		user: {
			onboardings: {},
		},
		settings: {
			company: {},
			contact_type: [],
			comision_categories: [],
			org_companies: [],
			provinces: [],
			sectors: [],
			site_configs: [],
			siteconfig_id: null,
		},
		filters: {
			startDate: '',
			endDate: '',
		},
		loadingApp: true,
		slide_locked: null,
	})

	const updateContextVal = (vals) => {
		// Función que actualiza el MainContext
		let newState = Object.assign({}, state)
		setState({ ...newState, ...vals })
	}
	const showConfetti = () => {
		toggleConfetti(true)
		setTimeout(() => {
			toggleConfetti(false)
		}, 5000)
	}

	const RetrieveData = async () => {
		let token = cookies.get('token')
		let size = window.innerWidth
		let newState = Object.assign({}, state)
		if (token !== undefined) {
			try {
				let response = await ValidateToken(token)
				// console.log(response.data)
				updateHeaders({ Authorization: `Token ${token}` })
				let newData = await LoginAndTokenData(response, 'validateToken')
				newState = { ...newState, ...newData }
			} catch (err) {
				// Manejar la redirección hacia el Login y mostrar una alerta
				// de que ese usuario que intentó burlar la seguridad no está
				// activcado o registrado en nuestro sistema.
				newState.isLogged = false
				newState.loadingApp = false
			}
		} else {
			let response = await ViewBrandAPI(window.location.host)
			newState.brand = response.data
			newState.loadingApp = false
			newState.isLogged = false
		}
		newState.size = size
		// console.log(newState)
		setState(newState)
	}
	React.useEffect(() => {
		// console.log('here')
		RetrieveData()
	}, [])
	return (
		<UserContext.Provider
			value={{
				state: state,
				updateContextVal: updateContextVal,
				showConfetti: showConfetti,
			}}>
			{confetti && <Confetti gravity={0.3} recycle={false} />}
			{props.children}
		</UserContext.Provider>
	)
}

// then make a consumer which will surface it
const UserConsumer = UserContext.Consumer

export default UserProvider
export { UserConsumer }
