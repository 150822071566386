import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import Help from '../elements/Help'
import { withContext } from '../../context/withContext'
import { GetChannels } from '../../API/core'

function SearchableVia(props) {
	const [vias, setVias] = useState([])
	const [value, setValue] = useState([])

	const initData = async () => {
		const { data } = await GetChannels(props.context.token)
		setVias(data)
		if (props.value && props.value !== null) setValue(data.find((e) => e.id === props.value))
	}

	useEffect(() => {
		initData()
	}, [props.value])

	const handleChange = (element) => {
		if (props.isMulti) {
			let arr = []
			for (let x in element) {
				arr.push(element[x])
			}
			setValue(element)
			if (props.onChange) props.onChange(arr)
		} else {
			setValue(element)
			if (props.onChange && element !== null) {
				props.onChange(element.id)
			} else {
				props.onChange(null)
			}
		}
	}

	const handleChangeFull = (element) => {
		if (props.isMulti) {
			let arr = []
			for (let x in element) {
				arr.push(element[x])
			}
			setValue(element)
			if (props.onChange) props.onChange(arr)
		} else {
			setValue(element)
			if (props.onChange && element !== null) {
				props.onChange(element)
			} else {
				props.onChange(null)
			}
		}
	}

	return (
		<div className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}

			<Select
				options={vias}
				{...props}
				value={value}
				onChange={props.fullVal ? handleChangeFull : handleChange}
				classNamePrefix="react-select"
			/>
		</div>
	)
}
export default withContext(SearchableVia)
