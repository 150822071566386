import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import NumberFormat from 'react-number-format'
import Help from '../elements/Help'
import { withContext } from '../../context/withContext'
import { FastSearchProperties } from '../../API/properties'
import styled from 'styled-components'

function SearchableProperties(props) {
	const [properties, setProperties] = useState([])
	const [loading, setLoading] = useState(false)
	const [value, setValue] = useState(props.isMulti ? [] : undefined)
	const [debounce, setDebounce] = useState(0)

	useEffect(() => {
		return () => {
			if (props.defaultValue) setValue(props.defaultValue)
		}
	}, [])

	useEffect(() => {
		if (props.defaultValue) setValue(props.defaultValue)
	}, [props.defaultValue])

	const handleChange = (element) => {
		if (props.returnFullValue) {
			props.onChange(element)
		} else {
			if (props.isMulti) {
				let arr = []
				for (let x in element) {
					arr.push(element[x].value)
				}
				setValue(element)
				if (props.onChange) props.onChange(arr)
			} else {
				setValue(element)
				if (props.onChange && element !== null) {
					props.onChange(element.value)
				} else {
					props.onChange(null)
				}
			}
		}
	}

	const loadOptions = (inputValue, callback) => {
		let arr = []
		let token = props.context.token
		let customQuery = inputValue
		clearTimeout(debounce)
		if (props.customQuery) {
			customQuery = `${inputValue}&${props.customQuery}`
		}
		setDebounce(
			setTimeout(() => {
				return FastSearchProperties(customQuery, token).then((response) => {
					setProperties(response.data.results)
					for (let x = 0; x < response.data.results.length; x++) {
						let data = response.data.results[x]
						arr.push({
							label: data.is_children ? ( // En caso de que sea un proyecto
								<React.Fragment>
									<span className="d-block">{`(${data.cid}) ${
										data.private_name ? data.private_name : data.name
									}`}</span>
									<small className="low-opacity">
										Desde{' '}
										<NumberFormat
											decimalScale={2}
											prefix={data.project_values && data.project_values.currency_min_sale + '$ '}
											thousandSeparator={true}
											displayType={'text'}
											value={data.project_values && data.project_values.min_sale}
										/>{' '}
										hasta{' '}
										<NumberFormat
											decimalScale={2}
											prefix={data.project_values && data.project_values.currency_max_sale + '$ '}
											thousandSeparator={true}
											displayType={'text'}
											value={data.project_values && data.project_values.max_sale}
										/>
									</small>
								</React.Fragment>
							) : (
								<React.Fragment>
									<span className="d-block">{`(${data.cid}) ${
										data.private_name ? data.private_name : data.name
									}`}</span>
									<small className="low-opacity">
										<ul className="low-opacity react-select-ul">
											{data.room && (
												<li>
													<span className="card__property-ammenities-bed"></span> {data.room}
												</li>
											)}
											{data.bathroom && (
												<li>
													<i className="fas fa-bath"></i> {data.bathroom}
												</li>
											)}
											{data.parkinglot && (
												<li>
													<i className="fas fa-car"></i> {data.parkinglot}
												</li>
											)}
											{data.floor_level && (
												<li>
													<img
														src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/MR.HOME/2020/7/9X9vKu5MZF-stairs.svg"
														height="15px"
													/>{' '}
													{data.floor_level}
												</li>
											)}
											{data.property_area && (
												<li>
													<span className="card__property-ammenities-size"></span>{' '}
													{data.property_area} {data.property_area_measurer}
												</li>
											)}
										</ul>
									</small>
								</React.Fragment>
							),
							value: data.uid,
							data: data,
						})
					}
					return callback(arr)
				})
			}, 500)
		)
	}

	return (
		<Div showPadding={!props.isMulti && props.defaultValue} className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label className="control-label" htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			<AsyncSelect
				isMulti={props.isMulti}
				isClearable={props.isClearable}
				value={value}
				loadedOptions={props.defaultValue}
				defaultOptions
				loadOptions={loadOptions}
				isLoading={loading}
				placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
				isDisabled={loading}
				onChange={handleChange}
				classNamePrefix="react-select"
				closeMenuOnSelect={props.closeMenuOnSelect}
			/>
		</Div>
	)
}
export default withContext(SearchableProperties)

const Div = styled.div`
	${(props) =>
		props.showPadding
			? `.react-select__value-container {
        padding: 10px;
    }`
			: ''}
`
