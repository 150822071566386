import { API } from '../utils/config'

export function FilterProjects(token, qs) {
	return API(`/projects/${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function ViewProject(token, uid) {
	return API(`/projects/view/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
export function EditProject(token, uid, data) {
	if (data) {
		return API.patch(`/projects/edit/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
	} else {
		return API(`/projects/edit/${uid}/`, { headers: { Authorization: 'Token ' + token } })
	}
}
export function CreateProject(token, data) {
	return API.post('/projects/new/', data, { headers: { Authorization: 'Token ' + token } })
}

export function ListPropertiesOfProject(token, uid) {
	return API(`/projects/properties/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
/*-----------------------------*/
/*        INVITATIONS          */
/*-----------------------------*/
export function ViewInvitation(token, uid) {
	return API(`/projects/invitations/view/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
export function ProjectInvitationsAPI(token, uid, param) {
	return API(`/projects/invitations/${uid}/${param ? param : ''}`, { headers: { Authorization: 'Token ' + token } })
}
export function InviteBrokerToProject(token, data) {
	return API.post('/projects/send-invite/', data, { headers: { Authorization: 'Token ' + token } })
}
export function ResendInvitation(token, data) {
	return API.post('/projects/resend-invite/', data, { headers: { Authorization: 'Token ' + token } })
}
export function EditInvitation(token, uid, data) {
	return API.patch(`/projects/invitations/edit/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function AcceptInvitation(uid, data) {
	if (data) {
		return API.patch(`/projects/accept-invite/${uid}/`, data)
	} else {
		return API(`/projects/accept-invite/${uid}/`)
	}
}
export function ListSentInvitations(token, qs) {
	if (qs) {
		return API(`/projects/invitations/sent/?${qs}`, { headers: { Authorization: 'Token ' + token } })
	} else {
		return API(`/projects/invitations/sent/`, { headers: { Authorization: 'Token ' + token } })
	}
}
export function HandleMasiveInvite(data) {
	return API.post(`/projects/masive-edit-invite/`, data)
}

/*-----------------------------*/
/*        RECORDS              */
/*-----------------------------*/
export function RegisterClient(token, data) {
	return API.post(`/projects/records/new/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function FilterClientRecords(token, qs) {
	return API(`/projects/client-records/${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function FilterProjectRecords(token, uid, qs) {
	return API(`/projects/records/${uid}${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function ListProjectsRecords(token, qs) {
	if (qs) {
		return API(`/projects/records/?${qs}`, { headers: { Authorization: 'Token ' + token } })
	} else {
		return API(`/projects/records/`, { headers: { Authorization: 'Token ' + token } })
	}
}
/*-----------------------------*/
/*        BUILDINGS / UNITS    */
/*-----------------------------*/
export function RetrieveBuildingsAPI(token, uid) {
	return API(`/projects/buildings/?project_uid=${uid}`, { headers: { Authorization: 'Token ' + token } })
}
export function DeleteBuildingAPI(token, uid) {
	return API.delete(`/projects/buildings/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
export function EditBuildingAPI(token, uid, data) {
	return API.patch(`/projects/buildings/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
}

export function BuildingsAPI(token, data, method, uid) {
	switch (method) {
		case 'post':
			return API.post('/projects/buildings/', data, { headers: { Authorization: 'Token ' + token } })
		case 'patch':
			return API.patch(`/projects/buildings/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
		default:
			return API('/projects/buildings/', { headers: { Authorization: 'Token ' + token } })
	}
}
export function ViewProjectUnits(token, project_uid) {
	return API(`/projects/${project_uid}/units`, { headers: { Authorization: 'Token ' + token } })
}
/*-----------------------------*/
/*   RESERVATIONS / TASKS      */
/*-----------------------------*/
export function CreateReservation(token, data) {
	return API.post(`/projects/reservations/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function ListReservations(token, qs) {
	return API(`/projects/reservations/${qs ? `?${qs}` : ''}`, { headers: { Authorization: 'Token ' + token } })
}
export function GetReservation(token, data, uid) {
	return API(`/projects/reservations/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
}
// Tasks
export function UpdateTask(token, data, uid) {
	return API.patch(`/projects/task/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
}
/*-----------------------------*/
/*        TEMPLATES            */
/*-----------------------------*/
export function ListOrViewTasksTemplate(token, uid) {
	if (uid) {
		return API(`/projects/templates/${uid}/`, { headers: { Authorization: 'Token ' + token } })
	} else {
		return API(`/projects/templates/`, { headers: { Authorization: 'Token ' + token } })
	}
}
export function NewOrEditTaskTemplate(token, data) {
	return API.post(`/projects/templates/`, data, { headers: { Authorization: 'Token ' + token } })
}
// export function EditTaskTemplate(token, uid, data) {
//     return API.patch(`/projects/templates/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
// }
export function DeleteTaskTemplate(token, uid) {
	return API.delete(`/projects/templates/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
// Template Tasks
export function DeleteTemplateTask(token, uid) {
	return API.delete(`/projects/template/task/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
// Task Category
export function ListTaskCategory(token) {
	return API(`/projects/task/categories/`, { headers: { Authorization: 'Token ' + token } })
}
export function NewTaskCategory(token, data) {
	return API.post(`/projects/task/categories/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function EditTaskCategory(token, uid, data) {
	return API.patch(`/projects/task/categories/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
}
/*-----------------------------*/
/*        ADMIN          */
/*-----------------------------*/
export function AuthorizeAccessToProject(token, data) {
	return API.post(`/projects/access/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function ListBrokersInvited(token, qs) {
	return API(`/projects/brokers-invited/${qs ? `?${qs}` : ''}`, { headers: { Authorization: 'Token ' + token } })
}
export function ListInvitationsOfBroker(token, uid) {
	return API(`/projects/invitations/user/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
export function ListBrokersCompany(params) {
	return API(`/projects/brokers-company/?${params}`)
}
