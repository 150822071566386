import axios from 'axios'

export const baseURL =
	process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : 'https://secure.alterestate.com/api/v1'
export const baseURLIntranet =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:8000/api/v1/intranet/'
		: 'https://secure.alterestate.com/api/v1/intranet/'
export const SOCKET_URL =
	process.env.NODE_ENV === 'development'
		? 'http://127.0.0.1:3200/api/v1/'
		: 'https://notifications.alterestate.com/api/v1/'

export const CLIENT_NAME = 'AlterEstate'
export const MapParams = { v: '1.exp', key: 'AIzaSyCj9aWYcVcxTI6dZkbR75V4J-mIGznOyIs' }
// Development
export const API_GOOGLE = 'AIzaSyCj9aWYcVcxTI6dZkbR75V4J-mIGznOyIs'
export const WEBURL = 'http://localhost:8000/'
export const SERVER_URL = baseURL
export const PHOTO_GALLERY = 'http://secure.alterestate.com/media/'

export const IntranetAPI = axios.create({
	baseURL: baseURLIntranet,
})
export const API = axios.create({
	baseURL: baseURL,
})

export const APIStats = axios.create({
	baseURL: 'https://stats.alterestate.com',
})
export const APISocket = axios.create({
	baseURL: SOCKET_URL,
})

export const stripeKEY =
	process.env.NODE_ENV === 'development' ? 'pk_test_ztj5s2wz9JWTA0eb0NsUnSaH' : 'pk_live_5Nw3IxHPOGyF239d9hUBGPIc'
export const planMensual = process.env.NODE_ENV === 'development' ? 'plan_H32k0D0K2SMoje' : 'plan_H8njZa5r4gYU2O'
export const planAnual =
	process.env.NODE_ENV === 'development' ? 'plan_H337MvkRtYbyqC' : 'price_1HZIhcKIRNg96RyYGCcD9uts'

export const pricesPlans = {
	pr_1_4: 25,
	pr_5_9: 22.0,
	pr_10_20: 20.0,
	pr_21_24: 19.0,
	pr_25_29: 18.0,
	pr_30_34: 16.0,
	pr_35_39: 15.0,
	pr_40_49: 14.0,
	pr_50_59: 13.0,
	pr_60: 12.0,
}

export const PricingPlans = {
	prod_id: 'prod_LZiK8ofGLDzFfv',
	USD: {
		monthly_id: 'price_1KsYtoKIRNg96RyYAozZ4xrn',
		yearly_id: 'price_1KsdcRKIRNg96RyYh5P1hxPW',
	},
	MXN: {
		monthly_id: 'price_1KsaXiKIRNg96RyYY038E5d1',
		yearly_id: 'price_1KsaXiKIRNg96RyYOwquMxCy',
	},
}
const pricesPlansUSD = [
	49.0, 68.0, 78.0, 104.0, 129.0, 144.0, 161.0, 176.0, 189.0, 210.0, 220.0, 240.0, 247.0, 266.0, 285.0, 288.0, 306.0,
	324.0, 342.0, 360.0, 367.5, 385.0, 391.0, 408.0, 425.0, 416.0, 432.0, 448.0, 464.0, 465.0, 480.5, 480.0, 495.0,
	510.0, 525.0, 522.0, 536.5, 551.0, 546.0, 560.0, 574.0, 588.0, 602.0, 616.0, 607.5, 621.0, 634.5, 624.0, 637.0,
	650.0, 637.5, 650.0, 662.5, 675.0, 660.0,
]
const pricesPlansMXN = [
	699.0, 1258.0, 1617.0, 1968.0, 2245.0, 2514.0, 2793.0, 3032.0, 3321.0, 3590.0, 3784.0, 3984.0, 4173.0, 4382.0,
	4575.0, 4784.0, 4964.0, 5166.0, 5358.0, 5380.0, 5544.0, 5698.0, 5727.0, 5976.0, 6200.0, 6214.0, 6453.0, 6692.0,
	6844.0, 7080.0, 7130.0, 7360.0, 7590.0, 7820.0, 8050.0, 7740.0, 7955.0, 8170.0, 8385.0, 8600.0, 8815.0, 9030.0,
	9245.0, 9460.0, 9675.0, 9614.0, 9823.0, 10032.0, 10241.0, 10450.0, 10659.0, 10868.0, 11077.0, 11286.0, 11495.0,
]
export const CalculateUSD = (amountUsers) => {
	let amount = 49.0
	amount = pricesPlansUSD[amountUsers - 1]
	return amount
}
export const CalculateMXN = (amountUsers) => {
	let amount = 699.0
	amount = pricesPlansMXN[amountUsers - 1]
	return amount
}
// Funcion para incluir el token en todas las solicitudes, para no tener que enviarla a cada momento
export function updateHeaders(headers) {
	let _headersName = Object.keys(headers)
	for (let i in _headersName) {
		API.defaults.headers.common[_headersName[i]] = headers[_headersName[i]]
	}
	for (let i in _headersName) {
		IntranetAPI.defaults.headers.common[_headersName[i]] = headers[_headersName[i]]
	}
}
