import React from 'react'
import { Routes, Route } from 'react-router-dom'
// LOCAL IMPORT
// Alterestate Version 2
import Login from './login'

export default function Authentication(props) {
	return (
		<Routes>
			<Route path="*" element={<Login />} />
		</Routes>
	)
}
