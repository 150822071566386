import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Help from '../elements/Help'
import { withContext } from '../../context/withContext'
import { ListCities } from '../../API/core'

function SearchableCities(props) {
    const [loading] = useState(false)
    const [value, setValue] = useState(props.isMulti ? [] : undefined)
    const [debounce, setDebounce] = useState(0)

    useEffect(() => {
        return () => {
            if (props.defaultValue) setValue(props.defaultValue)
        }
    }, [])

    useEffect(() => {
        if (props.defaultValue) setValue(props.defaultValue)
    }, [props.defaultValue])

    const handleChange = (element) => {
        if (props.isMulti) {
            let arr = []
            for (let x in element) {
                if (props.fullVal) {
                    // Si necesita el valor completo
                    arr.push(element[x])
                } else {
                    // entonces solo devolverme el val
                    arr.push(element[x].value)
                }
            }
            setValue(element)
            if (props.onChange) props.onChange(arr)
        } else {
            setValue(element)
            if (props.onChange && element !== null) {
                if (props.fullVal) {
                    // Si necesita el valor completo
                    props.onChange(element)
                } else {
                    props.onChange(element.value)
                }
            } else {
                props.onChange(null)
            }
        }
    }

    const loadOptions = (inputValue, callback) => {
        // let qs = 'province=31&name=' + inputValue
        let qs = `name=${inputValue}`

        let arr = []
        clearTimeout(debounce)
        setDebounce(
            setTimeout(() => {
                return ListCities(`?${qs}&page_size=150&${props.params}`).then((response) => {
                    for (let x = 0; x < response.data.length; x++) {
                        let data = response.data[x]
                        arr.push({
                            value: data.id,
                            label: `${data.name}`,
                        })
                    }
                    return callback(arr)
                })
            }, 500)
        )
    }
    return (
        <div className={props.sm ? 'form-group-sm' : 'form-group'}>
            {props.label ? (
                <label htmlFor={props.id}>
                    {props.label}: {props.required ? <span className="danger">*</span> : null}
                </label>
            ) : null}
            {props.help ? <Help id={props.id} help={props.help} /> : null}
            <AsyncSelect
                defaultOptions={!props.disabled}
                isMulti={props.isMulti}
                isClearable={props.hasOwnProperty('isClearable')}
                value={value}
                loadOptions={loadOptions}
                isLoading={loading}
                placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
                isDisabled={loading || props.disabled}
                onChange={handleChange}
                classNamePrefix="react-select"
                // Booleans
                openMenuOnClick={props.openMenuOnClick}
                closeMenuOnSelect={props.closeMenuOnSelect}
                noOptionsMessage={props.noOptionsMessage}
            />
        </div>
    )
}
export default withContext(SearchableCities)
