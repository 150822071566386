import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { ToggleSwitch } from '../FormGroups'

export default function Switcher(props) {
	return (
		<Container>
			<Row className="pb-4" id={props.id} data-tip={props.toolTip}>
				<Col xs={10}>
					<Label htmlFor={props.id}>
						{props.title} {props.required && <span className="danger">*</span>}{' '}
						{props.new && <span className="hot">NEW</span>}
					</Label>
					<Small>{props.subtitle}</Small>
				</Col>
				<Col xs={2} className="float-right">
					<ToggleSwitch
						required={props.required}
						id={props.id}
						value={props.value}
						className="float-right"
						disabled={props.disabled}
						onChange={props.onChange}
					/>
				</Col>
			</Row>
			{props.enableTooltip && <ReactTooltip effect="solid" />}
		</Container>
	)
}
const Container = styled.div`
	input:disabled {
		cursor: not-allowed !important;
		opacity: 0.5 !important;
	}
`
const Label = styled.label`
	font-size: 1rem;
	margin-bottom: 0px;
	display: block;
`
const Small = styled.small`
	font-size: 0.9rem;
	opacity: 0.7;
`
