import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
// LOCAL IMPORT
import Dashboard from '../Pages/Dashboard'
import Locations from '../Pages/Locations'
import PageNotFound from '../Pages/404'
import MobileFooterBar from './MobileFooterBar'
// Pages
const Companies = React.lazy(() => import('../Pages/Companies'))
const Users = React.lazy(() => import('../Pages/Users'))
const Settings = React.lazy(() => import('../Pages/Settings'))
const CheckLink = React.lazy(() => import('../Pages/CheckLink'))

export default function Content(props) {
    return (
        <Suspense fallback={<div>Cargando...</div>}>
            <div className={`${props.showBars && 'content'}`} id="alterestate-container">
                <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path="companies/*" element={<Companies />} />
                    <Route path="users/*" element={<Users />} />
                    <Route path="settings/*" element={<Settings />} />
                    <Route path="check-link/" element={<CheckLink />} />
                    <Route path="locations/" element={<Locations />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
                {props.showBars && <MobileFooterBar />}
            </div>
        </Suspense>
    )
}
