import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import ReactTooltip from 'react-tooltip'
// local import
import Plus from '../static/images/icons/plus.svg'

function SidebarContent(props) {
    const { collapse, settings, brand, toggle, modal, company, user, permits } = props
    const [modalNewSite, toggleNewSite] = useState(false)

    let links = [
        { text: 'Escritorio', link: '/', icon: 'clipboard', new: false, view: true, exact: true },
        {
            text: 'Compañías',
            link: '/companies?sort=-timestamp&page=1',
            icon: 'briefcase',
            new: false,
            view: true,
        },
        {
            text: 'Usuarios',
            link: '/users?page=1&sort=-date_joined',
            icon: 'trending-up',
            new: false,
            view: true,
        },
        {
            text: 'Enlaces (Ventas)',
            link: '/check-link',
            icon: 'globe',
            new: false,
            view: true,
        },
        {
            text: 'Ubicaciones',
            link: '/locations',
            icon: 'map',
            new: false,
            view: true,
        },
        // {
        // 	text: 'Sitios Webs',
        // 	link: '/sites',
        // 	icon: 'sidebar',
        // 	new: false,
        // 	view: true,
        // },
        // {
        // 	text: 'Propiedades',
        // 	link: '/properties',
        // 	icon: 'home',
        // 	new: false,
        // 	view: true,
        // },
        // {
        // 	text: 'Master Brokers',
        // 	link: '/projects',
        // 	icon: 'star',
        // 	new: false,
        // 	view: true,
        // },
    ]
    let trexo = [
        {
            text: 'Mis Ventas',
            link: '/transactions',
            icon: 'dollar-sign',
            new: false,
            view: true,
        },
        {
            text: 'Campañas',
            link: '/campaigns/round-robins',
            icon: 'rss',
            new: false,
            view: true,
        },
        {
            text: 'Reportes',
            link: '/reports',
            icon: 'fas fa-angle-double-up',
            fontAwesome: true,
            new: false,
            view: true,
        },
    ]

    return (
        <React.Fragment>
            <div
                id="logo"
                className={collapse ? 'sidebar-top-section-collapse sidebar-top-section' : 'sidebar-top-section'}>
                <img
                    src={brand.favicon}
                    alt="Logo Only"
                    className={!collapse ? 'sidebar__logo' : 'sidebar__logo sidebar__logo__collapse'}
                />
            </div>

            <div
                className={
                    !collapse
                        ? 'sidebar-navigation-items'
                        : 'sidebar-navigation-items sidebar-navigation-items-collapse'
                }>
                <div className="sidebar-navigation-section">
                    {!collapse && <h5>AlterEstate</h5>}
                    {links.map((el, key) => (
                        <div key={key}>
                            {collapse && (
                                <ReactTooltip
                                    place="right"
                                    className="bringTop"
                                    id={el.text}
                                    type="dark"
                                    effect="solid">
                                    <strong>{el.text}</strong>
                                </ReactTooltip>
                            )}
                            {el.view && (
                                <NavLink
                                    data-tip
                                    data-for={el.text}
                                    data-val={el.text}
                                    key={key}
                                    onClick={props.toggleMobile}
                                    className="sidebar-navigation-item"
                                    id={el.text}
                                    to={el.link}>
                                    {el.fontAwesome ? (
                                        <i className={el.icon}></i>
                                    ) : (
                                        <FeatherIcon size={collapse ? 18 : 14} icon={el.icon} />
                                    )}
                                    {!collapse && (
                                        <span className="position-absolute" style={{ marginLeft: '10px' }}>
                                            {el.text}
                                        </span>
                                    )}
                                    {el.new && <span className="hot">NEW</span>}
                                </NavLink>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* <div
				className={
					!collapse
						? 'sidebar-navigation-items'
						: 'sidebar-navigation-items sidebar-navigation-items-collapse'
				}>
				<div className="sidebar-navigation-section">
					{!collapse && <h5>Trexo</h5>}

					{trexo.map((el, key) => (
						<div key={key}>
							{collapse && (
								<ReactTooltip
									place="right"
									className="bringTop"
									id={el.text}
									type="dark"
									effect="solid">
									<strong>{el.text}</strong>
								</ReactTooltip>
							)}
							{el.view && (
								<NavLink
									data-tip
									data-for={el.text}
									data-val={el.text}
									key={key}
									className="sidebar-navigation-item"
									onClick={props.toggleMobile}
									id={el.text}
									to={el.link}>
									{el.fontAwesome ? (
										<i className={el.icon}></i>
									) : (
										<FeatherIcon size={collapse ? 18 : 14} icon={el.icon} />
									)}
									{!collapse && (
										<span className="position-absolute" style={{ marginLeft: '12px' }}>
											{el.text}
										</span>
									)}
									{el.new && <span className="hot">NEW</span>}
								</NavLink>
							)}
						</div>
					))}
				</div>
			</div> */}
        </React.Fragment>
    )
}

export default SidebarContent
