import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components'
// Local Import
import { withContext } from '../context/withContext'
import SidebarContent from './SidebarContent'

function Sidebar(props) {
	const user = props.context.user
	const settings = props.context.settings
	const company = props.context.settings.company
	const permits = props.context.user.permits
	const brand = props.context.brand
	const [modal, toggle] = useState(false)
	const { collapse } = props

	return (
		<Container
			collapsed={collapse}
			className="no-print"
			sidebarColor={brand.sidebar_color}
			highlight_color={brand.highlight_color}>
			<CollapseBtn
				data-tip
				data-for="collapse"
				left={collapse ? '45px' : '206px'}
				onClick={props.handleCollapse}
				sidebarColor={brand.sidebar_color}>
				{collapse ? (
					<FeatherIcon size={16} icon="chevron-right" />
				) : (
					<FeatherIcon size={16} icon="chevron-left" />
				)}
			</CollapseBtn>
			<ReactTooltip place="right" id="collapse" type="dark" effect="solid">
				{collapse ? 'Expandir' : 'Reducir'}
			</ReactTooltip>
			<div className={collapse ? 'sidebar collapse-sidebar' : 'sidebar'}>
				<SidebarContent
					collapse={collapse}
					brand={brand}
					user={user}
					settings={settings}
					company={company}
					permits={permits}
					toggle={() => toggle(!modal)}
					modal={modal}
				/>
			</div>
		</Container>
	)
}

const CollapseBtn = styled.a`
	position: fixed;
	z-index: 1050;
	left: ${(props) => props.left};
	background-color: ${(props) => props.sidebarColor};
	color: #ffffff !important;
	padding: 5px 5px 1px 5px;
	border-radius: 50%;
	top: 8px;
	transition: all 0.3s;
	@media only screen and (max-width: 1024px) {
		display: none;
	}
`
const Container = styled.div`
	.hot {
		position: absolute;
		right: 10%;
	}
	${(props) =>
		props.collapsed &&
		css`
			.hot {
				display: none !important;
			}
		`}
	.sidebar {
		background-color: ${(props) => props.sidebarColor};
	}
	.sidebar-navigation-item.active {
		border-color: ${(props) => props.highlight_color};
	}
`
export default withContext(Sidebar)
