import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Intent, Spinner } from '@blueprintjs/core'
// local import
import { withContext } from '../../../context/withContext'
import { ThSort } from '../../../Components/elements'
import NoResults from '../../../static/images/no_results.png'

const LocationsTable = (props) => {
    const { locations } = props
    const [params, setParams] = useState(null)
    const tableHeight = window.innerHeight - 175

    useEffect(() => {
        setParams(props.nextParams)
    }, [props.nextParams])

    return (
        <Container className='py-3'>
            {locations.length === 0 && !props.loading ? (
                <NoResultsContainer>
                    <div className="not-found">
                        <NotFoundImg src={NoResults} alt="No Results" />
                        <Title className="mt-4">No se encontraron resultados.</Title>
                    </div>
                </NoResultsContainer>
            ) : (
                <>

                    <InfiniteScroll
                        className="table-responsive"
                        dataLength={locations.length}
                        next={() => props.RetrieveData(params)}
                        hasMore={params !== null}
                        height={tableHeight}
                        loader={<LoaderContainer><Spinner intent={Intent.PRIMARY} size="24" /></LoaderContainer>}>
                        <table className="bp3-html-table bp3-html-table-bordered bp3-html-table-condensed bp3-html-table-striped bp3-interactive sortTable w-100">
                            <tr className="sticky-el sticky-top">

                                <ThSort
                                    id="id"
                                    mid="-id"

                                    style={{ minWidth: '50px', textAlign: "center" }}
                                    sort={props.sort}
                                    loading={props.loading}
                                    onClick={props.handleSort}
                                    label="ID"
                                    chevronPosition="right"
                                />
                                <ThSort
                                    id="name"
                                    mid="-name"

                                    style={{ width: 'content' }}
                                    sort={props.sort}
                                    loading={props.loading}
                                    onClick={props.handleSort}
                                    label="Nombre"
                                    chevronPosition="right"
                                />
                                <th style={{ width: 'content' }}>Ciudad</th>
                                <th style={{ minWidth: '80px' }}>Provincia</th>
                                <th style={{ minWidth: '80px' }}>País</th>

                            </tr>
                            {locations.map((e, key) => (
                                <TRow key={key} >

                                    <td className='text-center'>{e.id}</td>
                                    <td >
                                        {e.name}
                                    </td>
                                    <td>{e.city?.name}</td>
                                    <td>{e.province?.name}</td>
                                    <td>{e.country?.name}</td>
                                </TRow>
                            ))}
                        </table>
                    </InfiniteScroll>
                </>
            )}
        </Container>
    )
}
const Container = styled.div`
	background: #fff;
	position: relative;
	height: 100%;
	/* .sticky-left,
	.sticky-right {
		background: #fff;
		z-index: 5;
	} */

	.checkbox-selection {
		margin: 0 !important;
	}

	table {
		overflow-x: scroll;
		border-collapse: separate;
	}
	th {
		font-weight: 600;
		text-transform: none;
		color: rgb(25, 36, 53);
	}
	td,
	th,
	tr {
		border: 0;
		border-right: 1px solid #eee;
		border-bottom: 1px solid #eee;
		cursor: pointer;
		white-space: nowrap;
	}
	.sticky-el {
		position: sticky !important;
	}
	.table-responsive {
		height: calc(100% - 10px);
		max-height: 100%;
	}

	.infinte-scroll {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.sticky-top {
		border-top: 1px solid #eee;
		background: #fff;
		z-index: 5;
	}
	.sticky-left {
		border-right: 1px solid #eee;
		left: 0;
	}
	.sticky-right {
		right: 0;
		border-left: 1px solid #eee;
	}
	.bp3-drawer {
		box-shadow: 0 10px 20px #0000003d !important;
	}
	.bp3-overlay .bp3-overlay-inline {
		z-index: 5000;
	}

	.bp3-control {
		padding-left: 35px;
	}
`

const TRow = styled.tr`
	&:hover {
		background: #f6f7f8;
	}
	.sticky-el {
		background: #fff;
	}

	${(props) =>
        props.isSelected &&
        css`
			background: #86cfcd;
			.sticky-el {
				background: #86cfcd;
				&:hover {
					background: #86cfcd;
				}
			}
			&:hover {
				background: #86cfcd;
			}
		`}
`

const NoResultsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50%;

	.not-found {
		text-align: center;
	}
`
const Title = styled.h1`
	font-size: 24px;
	font-weight: 400;
	color: #333;
`

const NotFoundImg = styled.img`
	width: 280px;
`

const LoaderContainer = styled.div`
display:flex;
justify-content: center;
align-items: center;
height: 50px;
`

export default withContext(LocationsTable)
