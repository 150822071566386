import React from 'react'
import Help from '../elements/Help'
import NumberFormat from 'react-number-format'

export default function Input(props) {
	const onKeyPress = (event) => {
		const keyCode = event.keyCode || event.which
		const keyValue = String.fromCharCode(keyCode)
		if (/\.|-/.test(keyValue)) event.preventDefault()
	}
	return props.columns === 2 ? (
		<div className={props.sm ? 'form-group-sm' : props.withoutPadding ? '' : 'form-group'}>
			<div className="row">
				<div className="col-xs-12 col-md-12 col-lg-4 text-right-lg">
					{props.label ? (
						<label className="control-label" htmlFor={props.id}>
							{props.label}: {props.required ? <span className="danger">*</span> : null}
						</label>
					) : null}
					{props.help ? <Help id={props.id} help={props.help} /> : null}
				</div>
				<div className="col-xs-12 col-md-12 col-lg-6">
					{props.label_help && <label className="d-block low-opacity">{props.label_help}</label>}
					<div className={props.icon ? 'input-icon' : null}>
						{props.iconFirst && props.icon ? (
							<span className="input-icon-addon">
								<i className={props.icon}></i>
							</span>
						) : null}
						{props.formattedVal ? (
							<NumberFormat
								{...props}
								defaultValue={props.defaultValue}
								value={props.value !== null ? props.value : ''}
								name={props.id}
								decimalScale={2}
								onKeyPress={props.normalizenumber ? onKeyPress : undefined}
								autoComplete={props.autoComplete}
								className={props.className ? props.className + ' form-control' : 'form-control'}
							/>
						) : (
							<input
								{...props}
								defaultValue={props.defaultValue}
								value={props.value !== null ? props.value : ''}
								name={props.id}
								invalid={props.invalid}
								valid={props.valid}
								tick={props.tick}
								onBlur={props.onBlur}
								onKeyPress={props.normalizenumber ? onKeyPress : undefined}
								autoComplete={props.autoComplete}
								className={props.className ? props.className + ' form-control' : 'form-control'}
							/>
						)}
						{props.iconLast && props.icon ? (
							<span className="input-icon-addon">
								<i className={props.icon}></i>
							</span>
						) : null}
					</div>
					{props.label_help_2 && <label className="d-block low-opacity">{props.label_help_2}</label>}
				</div>
			</div>
		</div>
	) : (
		<div className={props.sm ? 'form-group-sm' : props.withoutPadding ? '' : 'form-group'}>
			{props.label ? (
				<React.Fragment>
					<label htmlFor={props.id}>
						{props.label}: {props.required ? <span className="danger">*</span> : null}
					</label>
				</React.Fragment>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			{props.label_help && <label className="d-block low-opacity">{props.label_help}</label>}
			<div className={props.icon ? 'input-icon' : null}>
				{props.iconFirst && props.icon ? (
					<span className="input-icon-addon">
						<i className={props.icon}></i>
					</span>
				) : null}
				{props.formattedVal ? (
					<NumberFormat
						decimalScale={2}
						{...props}
						defaultValue={props.defaultValue}
						name={props.id}
						value={props.value !== null ? props.value : ''}
						onKeyPress={props.normalizenumber ? onKeyPress : undefined}
						autoComplete={props.autoComplete}
						className={props.className ? props.className + ' form-control' : 'form-control'}
					/>
				) : (
					<input
						{...props}
						defaultValue={props.defaultValue}
						name={props.id}
						value={props.value !== null ? props.value : ''}
						invalid={props.invalid}
						valid={props.valid}
						tick={props.tick}
						onBlur={props.onBlur}
						onKeyPress={props.normalizenumber ? onKeyPress : undefined}
						autoComplete={props.autoComplete}
						className={props.className ? props.className + ' form-control' : 'form-control'}
					/>
				)}
				{props.iconLast && props.icon ? (
					<span className="input-icon-addon">
						<i className={props.icon}></i>
					</span>
				) : null}
			</div>
			{props.label_help_2 && <label className="d-block low-opacity">{props.label_help_2}</label>}
		</div>
	)
}
