import React, { useState, useEffect } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Help from '../elements/Help'
import styled from 'styled-components'
import { withContext } from '../../context/withContext'
import { FilterUsers } from '../../API/core'

function SearchableContact(props) {
	const [loading] = useState(false)
	const [value, setValue] = useState(props.isMulti ? [] : undefined)
	const [debounce, setDebounce] = useState(0)

	useEffect(() => {
		// return () => {
		// 	if (props.defaultValue) {
		// 		setValue({ label: props.defaultValue, value: props.defaultValue })
		// 	}
		// }
	}, [])

	// useEffect(() => {
	// 	if (props.defaultValue) {
	// 		setValue({ label: props.defaultValue, value: props.defaultValue })
	// 	}
	// }, [props.defaultValue])

	const handleChange = (e) => {
		// console.log(e.target.value)
	}

	// const handleChange = (element) => {
	// 	// Aquí hay un forze, revisar después.
	// 	if (props.isMulti) {
	// 		let arr = []
	// 		if (element.length > 0) {
	// 			for (let x in element) {
	// 				arr.push(element[x].value)
	// 			}
	// 		} else {
	// 			arr.push(element.value)
	// 		}
	// 		setValue(element)
	// 		if (props.onChange) {
	// 			if (props.ReactSelect) {
	// 				props.onChange(element)
	// 			} else {
	// 				props.onChange(arr)
	// 			}
	// 		}
	// 	} else {
	// 		setValue(element)
	// 		if (props.onChange && element !== null) {
	// 			props.onChange(element)
	// 		} else {
	// 			props.onChange(null)
	// 		}
	// 	}
	// }

	// const handleCreate = (e) => {
	// 	handleChange({
	// 		label: e,
	// 		value: e,
	// 	})
	// 	props.captureData()
	// }

	// const loadOptions = (inputValue, callback) => {
	// 	let qs = 'page_size=5&search=' + inputValue
	// 	let arr = []
	// 	let token = props.context.token
	// 	clearTimeout(debounce)
	// 	setDebounce(
	// 		setTimeout(() => {
	// 			return FilterUsers(token, qs).then((response) => {
	// 				for (let x = 0; x < response.data.results.length; x++) {
	// 					let data = response.data.results[x]
	// 					arr.push({
	// 						label: (
	// 							<ElementContainer className="media">
	// 								<img
	// 									className="mr-3"
	// 									src={
	// 										data.avatar ||
	// 										'https://alterestate.s3.amazonaws.com/static/user-2/84RXAS67YS-wQCKehJoPP'
	// 									}
	// 								/>
	// 								<div className="media-body">
	// 									<strong>{data.full_name}</strong>
	// 									<small className="low-opacity d-block">
	// 										{data.company} - Inivitar Colaborador
	// 									</small>
	// 								</div>
	// 							</ElementContainer>
	// 						),
	// 						value: props.valueFetch ? data[props.valueFetch] : data.uid,
	// 					})
	// 				}
	// 				return callback(arr)
	// 			})
	// 		}, 500)
	// 	)
	// }

	return (
		<div className={props.sm ? 'form-group-sm' : 'form-group'}>
			{props.label ? (
				<label htmlFor={props.id}>
					{props.label}: {props.required ? <span className="danger">*</span> : null}
				</label>
			) : null}
			{props.help ? <Help id={props.id} help={props.help} /> : null}
			<input className="form-control" onChange={props.onChange} placeholder="Correo electrónico del broker" />
			{/* <AsyncCreatableSelect
                isClearable
                cacheOptions
                // functions
                value={value}
                loadOptions={loadOptions}
                isDisabled={loading}
                onChange={handleChange}
                onCreateOption={handleCreate}
                // Props
                isMulti={props.isMulti}
                // loadedOptions={props.defaultValue}
                openMenuOnClick={false}
                defaultOptions={props.defaultOptions}
                isLoading={props.isLoading}
                placeholder={props.placeholder ? props.placeholder : 'Buscar por nombre completo o correo'}
                classNamePrefix="react-select"
            /> */}
		</div>
	)
}
const ElementContainer = styled.div`
	img {
		width: 28px;
		height: 28px;
		object-fit: cover;
		border-radius: 50%;
	}
`
export default withContext(SearchableContact)
